import { PiggyBank } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import {
  CardListActions,
  DefaultBulkActionButtons,
  EditorToolbar,
  MorePagination,
  validation,
} from "../../components";
import SanitizedP from "../../components/field/SanitizedP";

const validate = {
  required: [validation.required()],
};
function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Currency created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "manage-dose-currencies-amounts");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
      title="Upload Clip"
    >
      <SimpleForm toolbar={<EditorToolbar />}>
        <SanitizedP>
          Note: You cannot select a currency that is already saved in the
          system.
        </SanitizedP>
        <ReferenceInput
          label="Currency"
          source="currency_id"
          reference="currencies"
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <TextInput
          fullWidth
          source="dose_amount"
          label="Amount"
          validate={validate.required}
        />
      </SimpleForm>
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Currency updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      <SimpleForm toolbar={<EditorToolbar showDelete />}>
        <TextInput
          fullWidth
          source="currency_id"
          label="Currency"
          validate={validate.required}
          disabled
        />
        <TextInput
          fullWidth
          source="dose_amount"
          label="Amount"
          validate={validate.required}
        />
      </SimpleForm>
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={[]}
      perPage={50}
      pagination={<MorePagination />}
      title="Dose Dedication Currency Amounts"
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<DefaultBulkActionButtons />}
      >
        <TextField source="id" />
        <TextField source="currency_id" label="Currency" />
        <TextField source="dose_amount" label="Amount" />
      </Datagrid>
    </List>
  );
}

export const DoseCurrenciesAmounts = {
  category: "dose",
  name: "manage-dose-currencies-amounts",
  list: ListView,
  create: CreateView,
  icon: PiggyBank,
  options: { label: "Dose Currencies" },
  edit: EditView,
};
