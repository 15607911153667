import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  ImageField,
  ImageInput,
  List,
  NumberField,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";

import { Megaphone } from "lucide-react";
import { EditorToolbar } from "../../components";

function ListView(props) {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <TextField source="link" />
        <ImageField source="img" sortable={false} />
        <BooleanField source="display_on_player" label="Show on player" />
        <BooleanField
          source="display_on_schedule"
          label="Show on schedule list"
        />
      </Datagrid>
    </List>
  );
}
function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <BooleanInput
        source="display_on_player"
        label="Show on player (ads will rotate every 5 minutes and show for 10 seconds)"
      />
      <BooleanInput
        source="display_on_schedule"
        label="Show on schedule list"
      />
      <TextInput source="link" />
      <br />
      <br />
      <h4>Image</h4>
      <p style={{ width: 600 }}>
        Image aspect ratio 10:1 (ideal size 1400x140)
      </p>
      <TextInput source="img" label="Image URL" />
      <p>-or-</p>
      <ImageInput
        source="FILE_photo"
        label="Image Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Tisha bav ad created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "tisha_bav_ad");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Tisha bav ad updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Edit>
  );
}

export const TbavAds = {
  category: "tbav",
  name: "tisha_bav_ad",
  create: CreateView,
  edit: EditView,
  icon: Megaphone,
  list: ListView,
  options: { label: "Tisha B'av Ads" },
};
