import React, { useEffect, useState } from "react";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import { useWatch, useFormContext } from "react-hook-form";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  SimpleForm,
  TextField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  SelectInput,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { CardListActions, EditorToolbar, validation } from "../../components";
const PageDescription = () => (
  <div className="page-description">
    <h1>Restricted Domains</h1>
    <p>
      This page displays a list of domains restricted based on specific
      restriction types. You can review, update, or manage restricted domains to
      ensure proper enforcement of rules.
    </p>
  </div>
);
const AddEditPageDescription = () => (
  <div className="page-description">
    <h1>Add or Edit Restricted Domain</h1>
    <p>
      Use this page to add a new domain or modify an existing one for
      restriction purposes. Specify the domain name and choose the appropriate
      restriction type to apply the required rules.
    </p>
  </div>
);
const filters = [
  <NumberField source="domain_name" label="Id" alwaysOn key="id" />,
  <TextInput
    source="domain_name"
    label="Domain Name"
    alwaysOn
    key="domain_name"
  />,
];

const RestrictionDescription = () => {
  const selectedTypeId = useWatch({ name: "type_id" });
  const dataProvider = useDataProvider();
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (selectedTypeId) {
      // Fetch the description dynamically based on the selected `type_id`
      dataProvider
        .getOne("restriction_types", { id: selectedTypeId })
        .then(({ data }) => {
          setDescription(data.description || "No description available");
        })
        .catch((error) => {
          console.error("Error fetching description:", error);
          setDescription("No description available");
        });
    } else {
      setDescription("");
    }
  }, [selectedTypeId, dataProvider]);

  return (
    <span
      style={{
        color: "gray",
        fontSize: "14px",
        fontStyle: "italic",
        marginBottom: "20px",
      }}
    >
      {selectedTypeId ? `Description: ${description}` : ""}
    </span>
  );
};

function ListView(props) {
  return (
    <>
      <PageDescription />
      <List
        {...props}
        actions={<CardListActions exporter={true} />}
        filters={filters}
        perPage={50}
        sort={{ field: "created_at", order: "DESC" }}
        title="Restricted Domains"
      >
        <Datagrid rowClick="edit">
          <TextField
            source="id"
            label="ID"
            validate={[validation.required()]}
          />
          <TextField source="domain_name" label="Domain Name" />
          <ReferenceField
            source="type_id"
            reference="restriction_types"
            label="Restriction Type"
            link={false} // This makes the field non-clickable
          >
            <TextField source="type" />
          </ReferenceField>
          <ReferenceField
            source="type_id"
            reference="restriction_types"
            label="Restriction Description"
            link={false} // This makes the field non-clickable
          >
            <TextField source="description" />
          </ReferenceField>
        </Datagrid>
      </List>
    </>
  );
}

const RenderEditForm = (redirect) => {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <AddEditPageDescription />
      <ReferenceInput
        source="type_id"
        reference="restriction_types"
        label="Restriction Type"
      >
        <SelectInput
          label="Restriction Type"
          optionText="type"
          validate={[validation.required()]}
        />
      </ReferenceInput>
      <RestrictionDescription />
      <TextInput
        source="domain_name"
        label="Domain Name"
        validate={[validation.required()]}
      />
    </SimpleForm>
  );
};

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Restricted domain updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {RenderEditForm({ redirect: false })}
    </Edit>
  );
}
function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Restricted domain created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "restricted_domains");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {RenderEditForm({ redirect: "edit" })}
    </Create>
  );
}
export const RestrictedDomains = {
  category: "database",
  name: "restricted_domains",
  icon: CloudQueueIcon,
  list: ListView,
  edit: EditView,
  create: CreateView,
  options: { label: "Restricted Domains" },
};
