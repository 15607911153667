import { ListPlus } from "lucide-react";
import { useEffect, useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import {
  CardListActions,
  DefaultBulkActionButtons,
  MorePagination,
} from "../../components";
import { authClient } from "../../server";

const cleanDedicationName = (name) =>
  name
    .split("<br>")
    .map((w) => w.trim())
    .join(" ");

let sources = {
  dedicationName: "dedication_name",
  dedicationDate: "dedication_day",
  dedicationType: "dose_dedication_type.label",
  label: "label",
  dedicationTypeId: "dedication_type_id",
  dose: "is_dose",
  status: "is_status",
  approved: "is_approved",
  doubleDose: "is_double_dose",
};

let labels = {
  dedicationType: "Dedication Type",
  dedicationDate: "Dedication Date",
  dedicatedFor: "Dedication Name",
  status: "Status",
  approved: "Approval status",
  revoked: "Revoked",
  from: "From",
  doubleDose: "Double Dose",
};

let titles = {
  doseDedications: "Dose Dedications",
  createDoseDedications: "Create Dose Dedications",
  editDoseDedications: "Edit Dose Dedications",
};

function ListChoices() {
  const [dedicationsList, setDedicationsList] = useState([]);

  useEffect(() => {
    authClient
      .get(
        "/admin/manage-dose-dedication-types?_start=0&_end=1000&order=ASC&_sort=created_at",
      )
      .then((res) => {
        setDedicationsList(res.json);
      })
      .catch(console.error);
  }, []);

  const listChoices = dedicationsList.map((dedication) => ({
    id: dedication.id,
    name: dedication.label,
  }));

  return (
    <SelectInput
      source={sources.dedicationTypeId}
      label={labels.dedicationType}
      choices={listChoices}
    />
  );
}

const filters = [
  <DateInput label={labels.from} source={sources.dedicationDate} alwaysOn />,
  <TextInput source={sources.dedicationName} alwaysOn />,
  //this function needs a label to appear in the "Additional Filter" list
  <ListChoices label={labels.dedicationType} />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions style={{ marginRight: 700 }} exporter={true} />}
      filters={filters}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title={titles.doseDedications}
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={
          <DefaultBulkActionButtons style={{ marginRight: 700 }} />
        }
      >
        <TextField
          source={sources.dedicationName}
          label={cleanDedicationName(labels.dedicatedFor)}
        />
        <TextField
          source={sources.dedicationType}
          label={cleanDedicationName(labels.dedicationType)}
        />
        <BooleanField source={sources.approved} label={labels.approved} />
        <DateField
          source={sources.dedicationDate}
          label={labels.dedicationDate}
          options={{ timeZone: "UTC" }}
        />
      </Datagrid>
    </List>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Dedication created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "manage-dose-dedications");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
      title={titles.createDoseDedications}
    >
      <SimpleForm>
        <DateInput
          source={sources.dedicationDate}
          label={labels.dedicationDate}
        />
        <TextInput
          source={sources.dedicationName}
          label={cleanDedicationName(labels.dedicatedFor)}
        />
        <BooleanInput
          source={sources.doubleDose}
          defaultValue={true}
          label={labels.doubleDose}
        />
        <BooleanInput
          source={sources.approved}
          defaultValue={true}
          label={labels.approved}
        />
        <ListChoices />
        <TextInput
          disabled={true}
          source={sources.dose}
          label={labels.status}
          defaultValue={true}
        />
        <TextInput
          disabled={true}
          source={sources.status}
          label={labels.status}
          defaultValue={true}
        />
      </SimpleForm>
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Dedication updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
      title={titles.editDoseDedications}
    >
      <SimpleForm>
        <TextInput
          source={sources.dedicationName}
          label={cleanDedicationName(labels.dedicatedFor)}
        />
        <ListChoices />
        <DateInput
          source={sources.dedicationDate}
          label={labels.dedicationDate}
        />
        <BooleanInput
          source={sources.doubleDose}
          defaultValue={true}
          label={labels.doubleDose}
        />
        <BooleanInput source={sources.approved} label={labels.approved} />
      </SimpleForm>
    </Edit>
  );
}

export const DoseDedications = {
  category: "dose",
  name: "manage-dose-dedications",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: ListPlus,
  options: { label: "Dose Dedications" },
  roles: ["dose_dose_dedications_admin"],
  permissions: {
    dose_dedications_admin: ["create", "list", "edit"],
  },
};
