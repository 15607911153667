import { Users2 } from "lucide-react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  FormTab,
  Labeled,
  List,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import {
  CardListActions,
  EditorToolbar,
  formatDateTimeInput,
} from "../../components";
import { authRoles } from "../../server";
import DeviceList from "./UserDevices.tsx";
import FollowingList from "./UserFollowing.tsx";
import { UsersRethink } from "./UsersRethink";
import VerificationList from "./UserVerification.tsx";

const roleChoices = authRoles.map((ar) => ({ id: ar.id, name: ar.name }));

const filters = [
  <TextInput source="id" alwaysOn />,
  <TextInput source="email" alwaysOn />,
  <TextInput source="name_first" alwaysOn label="First name" />,
  <TextInput source="name_last" alwaysOn label="Last name" />,
  <DateInput
    label="From"
    source="created_at.>"
    alwaysOn
    format={formatDateTimeInput}
  />,
  <DateInput
    label="To"
    source="created_at.<"
    alwaysOn
    format={formatDateTimeInput}
  />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={filters}
      perPage={25}
      sort={{
        field: "email",
        order: "ASC",
      }}
      title="Users"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="email" />
        <TextField source="name_first" sortable={false} />
        <TextField source="name_last" sortable={false} />
        <DateField source="created_at" />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Speaker updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      <TabbedForm toolbar={<EditorToolbar />}>
        <FormTab label="Details" replace>
          <Labeled>
            <DateField source="created_at" />
          </Labeled>
          <BooleanInput source="verified" />
          <TextInput source="email" />
          <TextInput source="user_login" />
          <TextInput source="screen_name" />
          <TextInput source="name_first" label="First name" />
          <TextInput source="name_last" label="Last name" />
          <BooleanInput source="is_female" label="Female" />
          <TextInput source="speaker_id" label="Speaker Id" />
          <AutocompleteArrayInput
            source="role"
            choices={roleChoices}
            label="Permissions"
          />
          <Labeled>
            <SelectInput
              source="upload_zone"
              choices={[
                { id: null, name: "Default" },
                { id: "sfo2", name: "DO SFO2" },
                { id: "nyc3", name: "DO NYC3" },
                { id: "awsS3UsEast", name: "AWS US East" },
              ]}
            />
          </Labeled>
        </FormTab>
        <FormTab label="Following" replace>
          <FollowingList styles={styles} />
        </FormTab>
        <FormTab label="Verification Attempts" replace>
          <VerificationList styles={styles} />
        </FormTab>
        <FormTab label="Devices" replace>
          <DeviceList styles={styles} />
        </FormTab>
        <FormTab label="Rethink Database" replace>
          <UsersRethink />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

const styles = {
  col: {
    display: "inline-block",
    width: 280,
    lineHeight: 1,
  },
  col_100: {
    display: "inline-block",
    width: 100,
  },
  col_120: {
    display: "inline-block",
    width: 120,
  },
  col_140: {
    display: "inline-block",
    width: 140,
  },
  col_160: {
    display: "inline-block",
    width: 160,
  },
  col_180: {
    display: "inline-block",
    width: 180,
  },
  col_wide: {
    display: "inline-block",
    width: 340,
  },
  header: {
    display: "inline-block",
    width: 280,
    fontWeight: 500,
  },
  header_100: {
    display: "inline-block",
    width: 100,
    fontWeight: 500,
  },
  header_120: {
    display: "inline-block",
    width: 120,
    fontWeight: 500,
  },
  header_140: {
    display: "inline-block",
    width: 140,
    fontWeight: 500,
  },
  header_160: {
    display: "inline-block",
    width: 160,
    fontWeight: 500,
  },
  header_180: {
    display: "inline-block",
    width: 180,
    fontWeight: 500,
  },
  header_wide: {
    display: "inline-block",
    width: 340,
    fontWeight: 500,
  },
  row: {
    outline: "1px solid #eee",
    paddingLeft: 85,
    lineHeight: "42px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: ".875rem",
  },
};

export const Users = {
  category: "database",
  name: "users",
  icon: Users2,
  list: ListView,
  options: { label: "Users" },
  edit: EditView,
  recordRepresentation: (record) => `${record.email}`,
};
