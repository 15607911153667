import { MenuItem } from "@mui/material";
import { TextCursorInput } from "lucide-react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { EditorToolbar } from "../../components";

function ListView(props) {
  return (
    <List {...props} sort={{ field: "id", order: "ASC" }}>
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <TextField source="discount_code" />
        <BooleanField source="is_active" />
        <TextField source="amount" />
        <TextField source="type" />
      </Datagrid>
    </List>
  );
}

function renderEditForm({ create }) {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      {create ? (
        <TextInput source="discount_code" />
      ) : (
        <TextField source="discount_code" />
      )}
      <BooleanInput source="is_active" />
      <TextInput source="amount" />
      <TextInput source="type" select>
        <MenuItem value="percent">Percent</MenuItem>
        <MenuItem value="amount">Amount</MenuItem>
      </TextInput>
    </SimpleForm>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Discount code created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "dose-discount-codes");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm({ create: true })}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Discount code updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Edit>
  );
}

export const DoseDiscountCodes = {
  category: "dose",
  name: "dose-discount-codes",
  create: CreateView,
  edit: EditView,
  icon: TextCursorInput,
  list: ListView,
  options: { label: "Discount Codes" },
};
