import { useCallback, useEffect, useState } from "react";
import { authClient } from "../server";

export async function getTemporalWorkflow(workflowId) {
  try {
    const response = await authClient.get(
      `/temporal/workflows/x/${workflowId}`,
    );
    if (response?.json) {
      response.json.raw.pendingActivities =
        response.json.raw.pendingActivities?.map((pendingActivity) => ({
          ...pendingActivity,
          /** Heartbeat payload is Base64-encoded JSON */
          heartbeat: JSON.parse(
            atob(
              pendingActivity?.heartbeatDetails?.payloads[0].data ||
                btoa("null"),
            ),
          ),
        }));
      return response.json;
    }
  } catch (err) {
    console.error("Error fetching workflow:", err);
  }
  return null;
}

export default function useTemporalWorkflow(workflowType, workflowId) {
  const [isLoading, setIsLoading] = useState(false);
  const [workflow, setWorkflow] = useState(null);

  function startWorkflow(taskQueue, args) {
    authClient
      .post(`/temporal/workflows/${workflowType}`, {
        taskQueue,
        workflowId,
        args,
      })
      .then(() => {
        setWorkflow(null);
        setIsLoading(true);
      });
  }

  const refresh = useCallback(() => {
    setIsLoading(true);
    getTemporalWorkflow(workflowId).then((response) => {
      setWorkflow(response);
      setIsLoading(false);
    });
  }, [workflowId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { workflow, isLoading, refresh, startWorkflow };
}
