import { CalendarClock } from "lucide-react";
import {
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  Edit,
  FunctionField,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { EditorToolbar, SpeakerInput } from "../../components";

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Schedule updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
      title="Notify Bar"
    >
      <SimpleForm toolbar={<EditorToolbar />}>
        <DateTimeInput
          source="run_at"
          label="Schedule Date &amp; Time (New York)"
        />
      </SimpleForm>
    </Edit>
  );
}

const filters = [
  <TextInput source="id" alwaysOn />,
  <TextInput source="lecture_id" alwaysOn />,
  <TextInput source="speaker_id" alwaysOn />,
  <DateInput source="run_at" alwaysOn />,
  <SelectInput
    alwaysOn
    source="status"
    choices={[
      { id: "pending", name: "Pending" },
      { id: "in_progress", name: "In Progress" },
      { id: "done", name: "Done" },
      { id: "failed", name: "Failed" },
    ]}
  />,
  <SpeakerInput source="speaker_id" alwaysOn />,
  <TextInput source="lecture_id" alwaysOn />,
  <SelectInput
    alwaysOn
    source="handler"
    label="Schedule Type"
    choices={[
      { id: "schedulePublishLecture", name: "Publish Lecture" },
      { id: "emailDoseBroadcast", name: "Dose Emails" },
      { id: "scheduleDisplayDailyDose", name: "Dose Releases" },
      { id: "publishDosePodcast", name: "Dose Podcast" },
    ]}
  />,
  <TextInput source="note" alwaysOn />,
];

function ListView(props) {
  return (
    <List
      {...props}
      exporter={true}
      filterDefaultValues={{ status: "pending" }}
      filters={filters}
      title="Schedules"
      sort={{ field: "run_at", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <FunctionField
          source="status"
          label="Status"
          render={(record) => {
            const { status } = record;
            if (!status) return null; // Can be null?
            let display = status;
            const split = status.split("_");
            if (split.length > 1) {
              display = `${split[0]} ${split[1]}`;
            }
            return (
              <span style={{ textTransform: "capitalize" }}>{display}</span>
            );
          }}
        />
        <DateField source="run_at" label="Schedule Date" showTime />
        <TextField source="speaker_name" label="Speaker" />
        <TextField source="file_name" label="Filename" />
        <TextField source="note" label="Note" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const Schedules = {
  category: "manage",
  name: "manage-schedules",
  edit: EditView,
  list: ListView,
  icon: CalendarClock,
  options: { label: "Schedules" },
  roles: ["dose_email_admin", "schedules_admin"],
  permission: {
    schedules_admin: ["list", "edit"],
  },
};
