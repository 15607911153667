import { WholeWord } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { CardListActions, EditorToolbar } from "../../components";

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar showDelete />}>
      <TextInput
        source="entry"
        label="Entry"
        helperText={
          <>
            A list of synonym words or phrases, separated by commas.
            <br />
            <br />
            Example:{" "}
            <em class="bold">funny, humor, humorous, comical, hilarious</em>
            <br />
            <br />
            Explicit mappings can be separated by arrows. For example:{" "}
            <em class="bold">
              humorous, comical, hilarious =&gt; funny, humor
            </em>
            <br />
            <br />
            In this explicit mapping, a search for 'comical' will produce
            results containing 'funny' or 'humor' but not actually the word
            'comical' unless that word was also placed on the right side of the
            <em class="bold">=&gt;</em>
            <br />
            <br />
            See more{" "}
            <a
              href="https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-synonym-tokenfilter.html#_solr_synonyms"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "rgba(0,0,0,0.54)" }}
            >
              developer notes here
            </a>
            .
          </>
        }
      />
    </SimpleForm>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Search synonyms created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "search-synonyms");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Search synonyms updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={[
        <TextInput source="id" alwaysOn />,
        <TextInput label="Entry" source="entry" alwaysOn />,
      ]}
      perPage={25}
      sort={{ field: "id", order: "ASC" }}
      title="Search Synonyms"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="entry" label="Entry" sortable={false} />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const SearchSynonyms = {
  category: "misc",
  name: "search-synonyms",
  create: CreateView,
  edit: EditView,
  icon: WholeWord,
  list: ListView,
  options: { label: "Search Synonyms" },
};
