import { LanguagesIcon } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { CardListActions, EditorToolbar } from "../../components";

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <TextInput source="name" label="Name" />
    </SimpleForm>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Language created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "languages");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Language updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={[
        <TextInput source="id" alwaysOn />,
        <TextInput label="Name" source="name" alwaysOn />,
      ]}
      perPage={25}
      sort={{ field: "name", order: "ASC" }}
      title="Languages"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" label="Name" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const Languages = {
  category: "database",
  name: "languages",
  create: CreateView,
  edit: EditView,
  icon: LanguagesIcon,
  list: ListView,
  options: { label: "Languages" },
};
