import { Shield } from "lucide-react";
import {
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import {
  CardListActions,
  DefaultBulkActionButtons,
  EditorToolbar,
  MorePagination,
  validation,
} from "../../components";
import SanitizedP from "../../components/field/SanitizedP";

const validate = {
  required: [validation.required()],
};
const imgNameMessage =
  "Image name with extension goes here (e.g. test.png). The image must be on https://cloud.digitalocean.com/spaces/torahanytime-cdn?i=955929&path=images%2F. Alternatively, an icon can be specified.";
function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Dedication type created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "manage-dose-dedication-types");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
      title="Dedication Type"
    >
      <SimpleForm toolbar={<EditorToolbar />}>
        <TextInput
          fullWidth
          source="label"
          label="Display Name"
          validate={validate.required}
        />
        <BooleanInput fullWidth source="primary_type" label="Primary type?" />
        <SanitizedP>{imgNameMessage}</SanitizedP>
        <TextInput fullWidth source="icon_string" label="Image Name" />
        <TextInput fullWidth source="position" label="Position" type="number" />
        <TextInput source="emoji" label="Emoji" />
      </SimpleForm>
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Dedication type updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      <SimpleForm toolbar={<EditorToolbar showDelete />}>
        <TextInput
          fullWidth
          source="label"
          label="Display Name"
          validate={validate.required}
        />
        <BooleanInput fullWidth source="primary_type" label="Primary type?" />
        <SanitizedP>{imgNameMessage}</SanitizedP>
        <TextInput fullWidth source="icon_string" label="Image Name" />
        <TextInput fullWidth source="position" label="Position" type="number" />
        <TextInput source="emoji" label="Emoji" />
      </SimpleForm>
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={[]}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Dose Dedication types"
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<DefaultBulkActionButtons />}
      >
        <TextField source="id" />
        <TextField source="icon_string" label="Image Name" />
        <TextField source="primary_type" label="Primary Type?" />
        <TextField source="emoji" label="Emoji" />
        <TextField source="label" label="Label" />
        <TextField source="position" label="Position" />
      </Datagrid>
    </List>
  );
}

export const DoseDedicationTypes = {
  category: "dose",
  name: "manage-dose-dedication-types",
  list: ListView,
  create: CreateView,
  icon: Shield,
  options: { label: "Dose Dedication Types" },
  edit: EditView,
};
