import { CheckCircle } from "lucide-react";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  SelectArrayInput,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { BulkActionsView, PublishingStatuses } from "./shared";

const filters = [
  <TextInput label="File ID" source="id" alwaysOn />,
  <TextInput label="Lecture ID" source="lecture_id" />,
  <SelectArrayInput
    style={{ width: 200 }}
    label="Status"
    source="publishing_status"
    choices={[
      { id: "2", name: "Queued" },
      { id: "3", name: "Rendering" },
      { id: "4", name: "Encoding" },
      { id: "5", name: "Finished" },
      { id: "500", name: "Error" },
    ]}
    alwaysOn
  />,
  <TextInput label="Title" source="lecture_title" />,
  <TextInput label="Speaker" source="speaker_name" alwaysOn />,
];

function ListView(props) {
  return (
    <List
      {...props}
      filters={filters}
      perPage={25}
      sort={{ field: "created", order: "DESC" }}
      title="Rendered"
    >
      <Datagrid rowClick="show" bulkActionButtons={<BulkActionsView />}>
        <TextField source="lecture_id" />
        <TextField source="lecture_title" />
        <TextField source="speaker_name" />
        <SelectField source="publishing_status" choices={PublishingStatuses} />
        <TextField source="name" />
        <TextField source="size" />
        <TextField source="user_id" />
        <TextField source="id" />
        <TextField source="mime_type" />
        <DateField source="updated_at" showDate showTime />
      </Datagrid>
    </List>
  );
}

function ShowView(props) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Rendered">
          <TextField source="lecture_id" />
          <TextField source="lecture_title" />
          <TextField source="speaker_name" />
          <SelectField
            source="publishing_status"
            choices={PublishingStatuses}
          />
          <TextField source="name" />
          <TextField source="size" />
          <TextField source="user_id" />
          <TextField source="mime_type" />
          <DateField source="created" showTime />
          <NumberField source="upload_time" />
          <TextField source="duration" />
          <BooleanField source="has_audio" />
          <TextField source="media_type" />
          <BooleanField source="is_clip" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const UploadsRendered = {
  category: "database",
  name: "uploads-rendered",
  icon: CheckCircle,
  list: ListView,
  options: { label: "Rendered" },
  show: ShowView,
};
