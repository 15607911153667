import resourceIcon from "@mui/icons-material/AttachMoney";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  List,
  NumberField,
  RadioButtonGroupInput,
  ReferenceField,
  SelectInput,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import {
  CardListActions,
  createResourceURL,
  CustomFormTab,
  formatDateTimeInput,
  isTabLocation,
  JsonTextField,
  MorePagination,
  parseDateTimeIntUTC,
  UserGenderField,
} from "../../components";
import { authClient } from "../../server";

const sources = [
  { id: "stripe", name: "stripe" },
  { id: "paypal", name: "paypal" },
];

const filters = [
  <SelectInput source="provider" choices={sources} alwaysOn />,
  <TextInput source="email" alwaysOn />,
  <TextInput source="user_id" label="User Id" alwaysOn />,
  <TextInput source="txn_id" label="Transaction" />,
  <TextInput source="description" label="Description" />,
  <SelectInput
    source="event"
    choices={[
      { id: "webhook", name: "Webhooks" },
      { id: "all", name: "All Records" },
    ]}
  />,
  <DateInput
    label="From"
    source="created_at.>"
    alwaysOn
    format={formatDateTimeInput}
    parse={parseDateTimeIntUTC}
  />,
  <DateInput
    label="To"
    source="created_at.<"
    alwaysOn
    format={formatDateTimeInput}
    parse={parseDateTimeIntUTC}
  />,
  <RadioButtonGroupInput
    alwaysOn
    label="Stripe Webhook Type"
    source="webhookType"
    options={{
      row: true,
    }}
    choices={[
      { id: "all", name: "All" },
      { id: "charge.refunded", name: "Refunds" },
      { id: "charge.succeeded", name: "Charges" },
    ]}
  />,
];

function getName(record) {
  try {
    if (record.provider === "stripe") {
      if (
        record.donations_log.payload.data.object.billing_details &&
        record.donations_log.payload.data.object.billing_details.name
      ) {
        return record.donations_log.payload.data.object.billing_details.name;
      }
      if (record.donations_log.payload.data.object.metadata?.donor_full_name) {
        return record.donations_log.payload.data.object.metadata
          .donor_full_name;
      }
      const fname =
        record.donations_log.payload.data.object.metadata.donor_firstname || "";
      const lname =
        record.donations_log.payload.data.object.metadata.donor_lastname || "";
      if (fname || lname) {
        return fname + " " + lname;
      } else {
        return record.donations_log.payload.data.object.metadata.name;
      }
    } else if (record.provider === "paypal") {
      return (
        record.donations_log.payload.payer_business_name ||
        record.donations_log.payload.address_name ||
        `${record.donations_log.payload.first_name} ${record.donations_log.payload.last_name}`
      );
    } else {
      return "";
    }
  } catch (error) {
    //console.error(error);
    return "";
  }
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={filters}
      pagination={<MorePagination />}
      perPage={50}
      sort={{ field: "id", order: "DESC" }}
      title="Donations"
      filterDefaultValues={{
        event: "webhook",
        webhookType: "all",
      }}
    >
      <Datagrid rowClick="show">
        <FunctionField label="Name" render={getName} />
        <TextField source="provider" />
        <TextField source="type" />
        <TextField source="amount_formatted" label="Amount" sortBy="amount" />
        <TextField source="currency_id" label="Currency" />
        <TextField source="email" />
        <TextField source="user_id" label="User Id" />
        <NumberField source="donations_count" label="Donations" />
        <TextField source="txn_id" label="Transaction Id" />
        <DateField source="created_at" showTime />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
}

const styles = {
  clickable: {
    cursor: "pointer",
  },
};

class DetailsView extends React.PureComponent {
  state = {
    rows: [],
  };

  componentDidMount() {
    if (isTabLocation(1)) {
      this.getData();
    }
  }

  async getData() {
    const {
      props: {
        fetchEnd,
        fetchStart,
        record: { id },
        showError,
      },
    } = this;
    fetchStart();
    const res = await authClient
      .get(`/admin/donations/${encodeURIComponent(id)}/logs`)
      .catch(showError)
      .finally(fetchEnd);
    if (!res || !res.json) {
      return;
    }
    this.setState({
      rows: res.json,
    });
  }

  render() {
    const {
      state: { rows },
    } = this;
    return <JsonTextField record={{ rows }} source="rows" />;
  }
}

const Details = DetailsView;

function clickedAllDonations(push, id) {
  return () => {
    const url = createResourceURL("donations", id, "show");
    push(url);
  };
}

class AllDonationsView extends React.PureComponent {
  state = {
    rows: [],
  };

  componentDidMount() {
    if (isTabLocation(2)) {
      this.getData();
    }
  }

  async getData() {
    const {
      props: {
        fetchEnd,
        fetchStart,
        record: { id },
        showError,
      },
    } = this;
    fetchStart();
    const res = await authClient
      .get(`/admin/donations/${encodeURIComponent(id)}/user`)
      .catch(showError)
      .finally(fetchEnd);
    if (!res || !res.json) {
      return;
    }
    this.setState({
      rows: res.json,
    });
  }
  render() {
    const {
      props: { push },
      state: { rows },
    } = this;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">Provider</TableCell>
            <TableCell variant="head">Amount</TableCell>
            <TableCell variant="head">Currency</TableCell>
            <TableCell variant="head">Transaction Id</TableCell>
            <TableCell variant="head">Email</TableCell>
            <TableCell variant="head">User Id</TableCell>
            <TableCell variant="head">Time</TableCell>
            <TableCell variant="head">Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((data) => (
            <TableRow
              key={data.id}
              onClick={clickedAllDonations(push, data.id)}
              style={styles.clickable}
            >
              <TableCell>
                <TextField record={data} source="provider" />
              </TableCell>
              <TableCell>
                <TextField
                  record={data}
                  source="amount_formatted"
                  label="Amount"
                  sortable={false}
                />
              </TableCell>
              <TableCell>
                <TextField
                  record={data}
                  source="currency_id"
                  label="Currency"
                  sortable={false}
                />
              </TableCell>
              <TableCell>
                <TextField
                  record={data}
                  source="txn_id"
                  label="Transaction Id"
                  sortable={false}
                />
              </TableCell>
              <TableCell>
                <TextField record={data} source="email" sortable={false} />
              </TableCell>
              <TableCell>
                <TextField record={data} source="user_id" sortable={false} />
              </TableCell>
              <TableCell>
                <DateField record={data} source="created_at" showTime />
              </TableCell>
              <TableCell>
                <TextField record={data} source="id" sortable={false} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}
const AllDonations = AllDonationsView;

function ShowView(props) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Donation" replace>
          <TextField source="provider" />
          <DateField source="created_at" showTime label="Date" />
          <TextField source="amount_formatted" label="Amount" />
          <TextField source="campaign" />
          <TextField source="email" />
          <TextField source="user_id" label="User id" />
          <ReferenceField
            label="Gender"
            source="user_id"
            reference="users"
            linkType={false}
            allowEmpty
          >
            <UserGenderField source="is_female" />
          </ReferenceField>
          <TextField
            source="cust_id"
            label="Customer id"
            format={(d) => {
              console.log(d);
              return d;
            }}
          />
          <NumberField source="donations_count" label="Donations" />
          <TextField source="txn_id" label="Transaction id" />
          <TextField source="status" />
          <TextField source="last_4" label="Last 4" />
          <TextField source="in_honor_of" label="In honor of" />
          <TextField source="donate_towards" label="Purpose" />
          <TextField source="id" label="Database id" />
        </Tab>
        <CustomFormTab label="Details" replace>
          <Details />
        </CustomFormTab>
        <Tab label="All Donations" replace>
          <AllDonations />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const Donations = {
  category: "database",
  name: "donations",
  icon: resourceIcon,
  list: ListView,
  show: ShowView,
  options: { label: "Donations" },
};
