import React, { useEffect, useState } from "react";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import { useWatch, useFormContext } from "react-hook-form";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  SimpleForm,
  TextField,
  TextInput,
  useDataProvider,
  Button,
  useNotify,
  useRefresh,
  useRedirect,
  useListContext,
} from "react-admin";
import { CardListActions, EditorToolbar, validation } from "../../components";
import DeleteIcon from "@mui/icons-material/Delete";
const PageDescription = () => (
  <div className="page-description">
    <h1>Restriction Types</h1>
    <p>
      This page lists all available restriction types used to manage domain
      restrictions. Review or update the restriction types to ensure accurate
      categorization and enforcement.
    </p>
  </div>
);
const AddEditPageDescription = () => (
  <div className="page-description">
    <h1>Add or Edit Restriction Type</h1>
    <p>
      Use this page to create a new restriction type or update an existing one.
      Provide a clear name and description to define the purpose and usage of
      the restriction type.
    </p>
  </div>
);
const filters = [
  <NumberField source="domain_name" label="Id" alwaysOn key="id" />,
  <TextInput source="type" label="Restriction Type" alwaysOn key="type" />,
];

const CustomBulkDeleteButton = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();

  const handleBulkDelete = async () => {
    try {
      const { data: domains } = await dataProvider.getList(
        "restricted_domains",
        {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "id", order: "ASC" },
          filter: { type_id: selectedIds },
        },
      );

      if (domains.length > 0) {
        notify("Cannot delete: Some selected restriction types are in use", {
          type: "error",
        });
        return;
      }

      await dataProvider.deleteMany("restriction_types", { ids: selectedIds });
      notify("Successfully deleted restriction types", { type: "success" });
      onUnselectItems();
      refresh();
    } catch (error) {
      notify("Error during bulk deletion", { type: "error" });
    }
  };

  return (
    <Button label="Delete" onClick={handleBulkDelete} color="error">
      <DeleteIcon />
    </Button>
  );
};

function ListView(props) {
  return (
    <>
      <PageDescription />
      <List
        {...props}
        actions={<CardListActions exporter={true} />}
        filters={filters}
        perPage={50}
        sort={{ field: "created_at", order: "DESC" }}
        title="Restricted Domains"
      >
        <Datagrid
          rowClick="edit"
          bulkActionButtons={<CustomBulkDeleteButton />}
        >
          <TextField
            source="id"
            label="ID"
            validate={[validation.required()]}
          />
          <TextField source="type" label="Restriction Name" />
          <TextField source="description" label="Restriction description" />
        </Datagrid>
      </List>
    </>
  );
}

const RenderEditForm = (redirect) => {
  const notify = useNotify();

  return (
    <SimpleForm
      redirect={redirect}
      toolbar={<EditorToolbar />}
      onFailure={(error) => {
        notify(`Error: ${error.message}`, { type: "error" });
      }}
    >
      <AddEditPageDescription />
      <TextInput
        source="type"
        label="Restriction Name"
        validate={[validation.required()]}
      />
      <TextInput
        source="description"
        label="Restriction Description"
        validate={[validation.required()]}
        fullWidth
      />
    </SimpleForm>
  );
};

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Restriction type updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {RenderEditForm({ redirect: false })}
    </Edit>
  );
}
function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Restriction type created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "restriction_types");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {RenderEditForm({ redirect: "edit" })}
    </Create>
  );
}
export const RestrictionTypes = {
  category: "database",
  name: "restriction_types",
  icon: NotInterestedOutlinedIcon,
  list: ListView,
  edit: EditView,
  create: CreateView,
  options: { label: "Restriction Types" },
};
