import React, { useEffect, useState } from "react";
import { Radio, Pencil, Plus, Trash2 } from "lucide-react";
import {
  BooleanInput,
  Create,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  useRedirect,
  TextInput,
  useGetList,
  useNotify,
  Button,
  ImageField,
  useDataProvider,
  useDelete,
  useRefresh,
} from "react-admin";
import {
  ConventionInput,
  // DateTimeInput,
  EditorToolbar,
  parseDateTimeInt,
} from "../../components";
import { authClient } from "../../server/index.js";
import { timezoneOptions } from "../../assets/js/timezones";
import SortablePage from "../../components/page/SortableFormPage.tsx";
import { useFormContext, useWatch } from "react-hook-form";
import "./live-events.css";

//date_end is no longer madatory
const validateDateCreated = (values) => {
  const errors = {};
  if (!values.date_start) {
    errors.date_start = ["Date start is required"];
  }

  return errors;
};

const SortableItem = ({ item, DeleteEvent, EditEvent }) => {
  return (
    <div className="sortable-item">
      <img width={100} height={100} className="item-img" src={item.pic_url} />
      <span className="event-title">{item.event_title}</span>
      <span>
        <span
          className={`mx-2 status-badge ${item.display_active == 1 ? "status-active" : "status-inactive"}`}
        >
          {item.display_active == 1 ? "Active" : "Inactive"}
        </span>
        <Button size="small" onClick={DeleteEvent} label="Delete">
          {" "}
          <Trash2 width={"15px"} height={"15px"} />
        </Button>
        &nbsp;&nbsp;
        <Button size="small" onClick={EditEvent} label="Edit">
          <Pencil width={"15px"} height={"15px"} />
        </Button>
      </span>
    </div>
  );
};
const EventCreateView = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const { data, total, error } = useGetList("manage-live-events", {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: "row_order", order: "ASC" },
  });

  if (!data) return null;
  const fetchedEvents = data;
  const handleSave = async (data) => {
    if (fetchedEvents && fetchedEvents.length > 0) {
      data.row_order = Number(fetchedEvents.length + 1);
    }
    try {
      await dataProvider.create("manage-live-events", {
        data: data,
      });
      notify("Live event created successfully!", { type: "success" });
      redirect("list", "manage-live-events");
    } catch (error) {
      notify(`Error: ${error.message || "Something went wrong"}`, {
        type: "error",
      });
    }
  };
  return (
    <SimpleForm toolbar={<EditorToolbar />} onSubmit={handleSave}>
      <BooleanInput source="display_active" label="Display" />
      <TextInput source="event_title" label="Title" fullWidth />
      <TextInput source="url" label="Event URL" fullWidth />
      <BooleanInput source="ladies" />
      <TextInput source="event_picture" label="Event Picture" />
      <ImageInput
        source="FILE_upload"
        label="Upload Event Picture"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <DateTimeInput
        source="date_start"
        label="Start"
        parse={parseDateTimeInt}
        required
      />
      {/* <DateTimeInput
        source="date_end"
        label="End"
        parse={parseDateTimeInt}
        helperText="End time is automatically 1 hour after the start time unless otherwise specified."
      /> */}
      <DateEnd />
      <ConventionInput source="convention_id" allowEmpty />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.convention_id > 0 && (
              <>
                <TextInput source="extension" />
                <SelectInput
                  source="time_zone"
                  label="Timezone"
                  choices={timezoneOptions}
                />
                <BooleanInput source="is_keynote" />
                <NumberInput
                  source="lecture_id"
                  allowEmpty
                  label="Lecture ID"
                />
                <TextInput
                  source="speaker_list"
                  allowEmpty
                  label="Speaker List (used by conventions)"
                />
              </>
            )
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  );
};

const DateEnd = () => {
  const dateStart = useWatch({ name: "date_start" });
  const { setValue } = useFormContext();

  useEffect(() => {
    // Add 3600 seconds (1 hour) to the date_start timestamp
    if (dateStart) {
      const newEndDate = dateStart + 3600 * 1000;
      // Only update if date_end is not already set or if it's less than the calculated end date
      if (!dateStart || dateStart < newEndDate) {
        setValue("date_end", newEndDate, { shouldTouch: true });
      }
    }
  }, [dateStart, setValue]);

  return (
    <DateTimeInput
      source="date_end"
      label="End"
      parse={parseDateTimeInt}
      helperText="End time is automatically 1 hour after the start time unless otherwise specified."
    />
  );
};

const ListView = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [saving, setSaving] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [hasOrderChanged, setHasOrderChanged] = useState(false);
  const { data, total, error, isLoading } = useGetList("manage-live-events", {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: "row_order", order: "ASC" },
  });
  const [deleteOne, { isLoading: isDeleting }] = useDelete();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (data) {
      setAllEvents(data);
      setEvents(data);
      setInitialLoad(false);
      setHasOrderChanged(false);
    }
  }, [data]);

  const DeleteEvent = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      await deleteOne("manage-live-events", { id: id })
        .then(() => {
          notify("Event deleted successfully", { type: "info" });
        })
        .catch((error) => {
          notify(`Error: ${error.message}`, { type: "warning" });
        });

      setSaving(true);
      const ids = events
        .filter((p) => p.id != id)
        .map((row, index, array) => {
          return {
            id: row.id,
            row_order: Number(index + 1),
          };
        });
      await authClient
        .patch("admin/live-events", ids)
        .catch((error) => notify(error));
      setSaving(false);

      setEvents(events.filter((p) => p.id != id));
    } else {
      return false;
    }
  };

  const saveEvent = async () => {
    if (saving || initialLoad || !hasOrderChanged) return;
    setSaving(true);
    const ids = events.map((row, index, array) => ({
      id: row.id,
      row_order: Number(index + 1),
    }));

    await authClient
      .patch("admin/live-events", ids)
      .catch((error) => notify(error));

    setSaving(false);
    setHasOrderChanged(false);
  };
  const EditEvent = (id) => {
    redirect("edit", "manage-live-events", id);
  };
  const createEvent = () => {
    redirect("create", "manage-live-events");
  };

  const scrollToEvent = (title) => {
    const escapedTitle = CSS.escape(title);
    const element = document.querySelector(
      `[data-event-title="${escapedTitle}"]`,
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.classList.add("highlight");
      setTimeout(() => element.classList.remove("highlight"), 2000);
    }
  };

  return (
    <div>
      {saving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <span className="loading loading-spinner loading-md"></span>
            <span>Saving changes...</span>
          </div>
        </div>
      )}
      <div className="head-title">
        <div className="head-title-content">
          <h3>Live Events</h3>
          <div className="search-input-container">
            <input
              className="search-input"
              type="text"
              placeholder="Search by event title"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                if (e.target.value) {
                  const matchingEvent = events.find((event) =>
                    event.event_title
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()),
                  );
                  if (matchingEvent) {
                    scrollToEvent(matchingEvent.event_title);
                  }
                }
              }}
            />
          </div>
        </div>
        <Button size="small" onClick={createEvent} label="Create">
          <Plus width={"15px"} height={"15px"} />
        </Button>
      </div>
      <SortablePage
        staticList
        info="Drag rows to reorder, then click 'Save'"
        items={events}
        renderItem={(item, index) => (
          <div data-event-title={item.event_title}>
            <SortableItem
              item={item}
              DeleteEvent={() => DeleteEvent(item.id)}
              EditEvent={() => EditEvent(item.id)}
            />
          </div>
        )}
        setItems={(newItems) => {
          setEvents(newItems);
          setHasOrderChanged(true);
        }}
        handleSave={saveEvent}
        title={`Live Events`}
        createItem={() => ({})}
        // onSortSave
      />
    </div>
  );
};
function renderEditForm({ redirect }) {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <BooleanInput source="display_active" label="Display" />
      <TextInput source="event_title" label="Title" fullWidth />
      <TextInput source="url" label="Event URL" fullWidth />
      <BooleanInput source="ladies" />
      <TextInput source="event_picture" label="Event Picture" />
      <ImageInput
        source="FILE_upload"
        label="Upload Event Picture"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <DateTimeInput
        source="date_start"
        label="Start"
        parse={parseDateTimeInt}
        required
      />
      <DateTimeInput
        source="date_end"
        label="End"
        parse={parseDateTimeInt}
        helperText="End time is automatically 1 hour after the start time unless otherwise specified."
      />
      <ConventionInput source="convention_id" allowEmpty />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.convention_id > 0 && (
              <>
                <TextInput source="extension" />

                <SelectInput
                  source="time_zone"
                  label="Timezone"
                  choices={timezoneOptions}
                />
                <BooleanInput source="is_keynote" />
                <NumberInput
                  source="lecture_id"
                  allowEmpty
                  label="Lecture ID"
                />
                <TextInput
                  source="speaker_list"
                  allowEmpty
                  label="Speaker List (used by conventions)"
                />
              </>
            )
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  );
}
function CreateView(props) {
  return <Create {...props}>{EventCreateView()}</Create>;
}
function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Live event updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm({ redirect: "list" })}
    </Edit>
  );
}
export const LiveEvents = {
  category: "manage",
  name: "manage-live-events",
  options: { label: "Live Events" },
  exact: true,
  icon: Radio,
  edit: EditView,
  create: CreateView,
  list: ListView,
};
