import { Type } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { CardListActions, EditorToolbar } from "../../components";

const EditTitle = () => <span>{`Speaker Title`}</span>;

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <TextInput source="title" label="Title" />
      <TextInput source="title_short" label="Short title" />
    </SimpleForm>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Speaker titlle created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "speaker-titles");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  console.log({ edit: props });
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Speaker titlle updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
      title={<EditTitle />}
    >
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      perPage={25}
      sort={{ field: "title", order: "ASC" }}
      title="Speaker Titles"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="title" label="Title" />
        <TextField source="title_short" label="Short title" />
        <NumberField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

export const SpeakerTitles = {
  category: "database",
  name: "speaker-titles",
  create: CreateView,
  edit: EditView,
  icon: Type,
  list: ListView,
  options: { label: "Speaker Titles" },
};
