import { Play } from "lucide-react";
import {
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";
import {
  CardListActions,
  CategoryInput,
  formatDateTimeInput,
  MorePagination,
  OrganizationInput,
  SpeakerInput,
} from "../../components";

const styles = {
  sm: { minWidth: 120 },
  lg: { minWidth: 180 },
};

const filters = [
  <OrganizationInput
    label="Organization"
    source="org"
    reference="organizations"
    alwaysOn
  />,
  <SpeakerInput
    label="Speaker"
    source="speaker"
    reference="speakers"
    alwaysOn
  />,
  <CategoryInput
    label="Category"
    source="category"
    reference="categories"
    alwaysOn
  />,
  <DateInput
    label="From"
    source="date_start"
    alwaysOn
    format={formatDateTimeInput}
  />,
  <DateInput
    label="To"
    source="date_end"
    alwaysOn
    format={formatDateTimeInput}
  />,
  <BooleanInput label="Only Clips" source="is_clip" alwaysOn />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={filters}
      perPage={100}
      pagination={<MorePagination />}
      sort={{ field: "total", order: "DESC" }}
      title="Lecture Stats"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="expand">
        <ReferenceField label="Title" source="id" reference="lectures">
          <TextField source="title" style={styles.lg} />
        </ReferenceField>
        <ReferenceField
          label="Category"
          source="category"
          reference="categories"
        >
          <TextField source="name" style={styles.lg} />
        </ReferenceField>
        <FunctionField
          label="Organization"
          render={(record) =>
            record.org ? (
              <ReferenceField
                record={record}
                source="org"
                reference="organizations"
                basePath="/organizations"
              >
                <TextField source="name" style={styles.lg} />
              </ReferenceField>
            ) : null
          }
        />

        <TextField source="host_name" label="Host" style={styles.lg} />
        <ReferenceField label="Speaker" source="speaker" reference="speakers">
          <TextField source="name" style={styles.lg} />
        </ReferenceField>
        <DateField source="date_created" style={styles.sm} />
        <DateField source="date_recorded" style={styles.sm} />
        <NumberField source="total" />
        <NumberField source="video_web_user" />
        <NumberField source="video_web_nonuser" />
        <NumberField source="video_ios_user" />
        <NumberField source="video_ios_nonuser" />
        <NumberField source="video_android_user" />
        <NumberField source="video_android_nonuser" />
        <NumberField source="download_web" />
        <NumberField source="download_ios" />
        <NumberField source="download_android" />
        <NumberField source="audio_phone_nonuser" />
        <NumberField source="audio_phone" />
        <NumberField source="audio_web_user" />
        <NumberField source="audio_web_nonuser" />
        <NumberField source="audio_ios_user" />
        <NumberField source="audio_ios_nonuser" />
        <NumberField source="audio_android_user" />
        <NumberField source="audio_android_nonuser" />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
}

export const LectureStats = {
  category: "stats",
  name: "stats-lectures",
  icon: Play,
  list: ListView,
  options: { label: "Lecture Stats" },
  roles: ["stat_viewer"],
  permissions: {
    stat_viewer: ["list"],
  },
};
