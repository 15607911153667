import { useEffect, useState, useCallback } from "react";
import {
  BooleanInput,
  Create,
  DateInput,
  Edit,
  FileField,
  FileInput,
  FormTab,
  FunctionField,
  ImageField,
  NumberInput,
  TabbedForm,
  TextInput,
  Toolbar,
  SaveButton,
  useNotify,
  useDataProvider,
  useRecordContext,
  useRefresh,
  useRedirect,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { TemporalProgressField } from "../../components/field/TemporalProgressField";
import { ExistingUrlField } from "../../components/field/ExistingUrlField";

import { EmailTab } from "./EmailTab";
import { InstagramTemplate } from "./InstagramTemplate";
import { SchedulesTab } from "./ScheduleTab";
import { WhatsAppTemplate } from "./WhatsAppTemplate";

import { baseURL } from "../../server";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as tus from "tus-js-client";
import { Link } from "react-router-dom";

const {
  REACT_APP_TUS_BUCKET_DAILY_DOSE: TUS_BUCKET_DAILY_DOSE = "daily-dose-prod",
  REACT_APP_TUS_PREFIX_DAILY_DOSE: TUS_PREFIX_DAILY_DOSE = "originals/",
  REACT_APP_TUS_ENDPOINT: TUS_ENDPOINT = "https://tusd.torahanytime.com/",
} = process.env;

const tusEndpoint = TUS_ENDPOINT;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function UploadThenSaveButton() {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isFileFinishedUploading, setIsFileFinishedUploading] = useState(false);
  // `progress` is 0-100, due to MaterialUI
  const [progress, setProgress] = useState(0);
  const convertTusUploadToFileInfo = useCallback(async (doseRecord) => {
    const file = doseRecord.FILE_original_video?.rawFile;
    if (!file) {
      return doseRecord;
    }
    const uploadFinished = new Promise((resolve) => {
      const upload = new tus.Upload(file, {
        // Endpoint is the upload creation URL from your tus server
        endpoint: tusEndpoint,
        // Retry delays will enable tus-js-client to automatically retry on errors
        retryDelays: [0, 3000, 5000, 10000, 20000],
        // Attach additional meta data about the file for the server
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        // Callback for errors which cannot be fixed using retries
        onError: (error) => {
          console.log(`Tus Upload failed because: ${error}`);
          setIsFileUploading(false);
          setIsFileFinishedUploading(false);
        },
        // Callback for reporting upload progress
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = (bytesUploaded / bytesTotal) * 100;
          setProgress(percentage);
        },
        // Callback for once the upload is completed
        onSuccess: () => {
          console.log("Download %s from %s", upload.file.name, upload.url);
          setIsFileFinishedUploading(true);
          setIsFileUploading(false);
          resolve(upload);
        },
      });

      // Check if there are any previous uploads to continue.
      upload.findPreviousUploads().then((previousUploads) => {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        setIsFileUploading(true);
        setIsFileFinishedUploading(false);
        setProgress(0);
        // Start the upload
        upload.start();
      });
    });

    const upload = await uploadFinished;

    delete doseRecord.FILE_original_video;
    doseRecord.FILEINFO_original_video = {
      bucket: TUS_BUCKET_DAILY_DOSE,
      prefix: TUS_PREFIX_DAILY_DOSE,
      /** Example: From `https://tusd.torahanytime.com/8d9e7d72d525cb5cb607b7d9f274238a+2~tQmI2C9TxIOw361bEx9tSlPtbNIECy5`, we want `8d9e7d72d525cb5cb607b7d9f274238a` */
      filename: upload.url.split("/").pop().split("+")[0],
      downloadUrl: upload.url,
      filetype: upload.file.type,
    };

    console.log("transformed doseRecord", doseRecord);

    return doseRecord;
  }, []);

  /** Since our api is outdated and uses OpenAPI 2.x, we cannot have a nullable
   * field in the request body. So we delete null fields. */
  const deleteNullFields = useCallback((doseRecord) => {
    // if (doseRecord.title === null) {
    //   doseRecord.title = "";
    // }
    if (doseRecord.dose_number === null) {
      delete doseRecord.dose_number;
    }
    if (doseRecord.released_at === null) {
      delete doseRecord.released_at;
    }
    return doseRecord;
  }, []);

  const transform = useCallback(
    (doseRecord) => {
      return convertTusUploadToFileInfo(deleteNullFields(doseRecord));
    },
    [convertTusUploadToFileInfo, deleteNullFields],
  );

  return (
    <Toolbar>
      <SaveButton type="button" transform={transform} />
      {isFileUploading && <CircularProgressWithLabel value={progress} />}
    </Toolbar>
  );
}

export function CreateDoseView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const validate = (values) => {
    const errors = {};

    if (!values.title) errors.title = "Title is required.";
    // if (!values.dose_number && values.dose_number !== 0)
    //   errors.dose_number = "Dose number  is required.";
    if (!values.lecture_id) errors.lecture_id = "Lecture Id is required.";
    // if (!values.released_at)
    //   errors.released_at = "Released at date is required.";
    // if (!values.FILE_original_video)
    //   errors.FILE_original_video = "Original Video is required.";

    return errors;
  };

  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Daily dose created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "daily_dose");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      <TabbedForm
        {...props}
        validate={(values) => validate(values)}
        toolbar={<UploadThenSaveButton />}
      >
        <FormTab label="Create" {...props}>
          <TextInput source="title" />
          <NumberInput source="dose_number" type="number" />
          <NumberInput source="lecture_id" type="number" label="Lecture Id" />
          <BooleanInput source="display" label="Display" defaultValue={false} />
          <BooleanInput
            source="double_dose"
            label="Double Dose"
            defaultValue={false}
          />
          <DateInput source="released_at" />
          <FileInput
            source="FILE_original_video"
            accept="video/*"
            label="Original Video file"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
}

const ClipNotesInput = () => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const notify = useNotify();
  const { register, setValue, getValues } = useFormContext();
  register("clips_new.notes");
  register("clips_new.ending");

  const loadNotes = async () => {
    try {
      const { data } = await dataProvider.getOne("clips_new", {
        id: record.clip_id,
      });
      setValue("clips_new.notes", data.notes);
      setValue("clips_new.ending", data.ending);
    } catch (error) {
      notify("Error fetching clip", { type: "error", error });
    }
  };

  const update = async (formValueKey, clipFieldKey) => {
    // Update the clips's notes when the input loses focus
    try {
      const { data } = await dataProvider.getOne("clips_new", {
        id: record.clip_id,
      });
      await dataProvider.update("clips_new", {
        id: record.clip_id,
        data: { ...data, [clipFieldKey]: getValues(formValueKey) },
        // previousData: {}, // Optional, depends on your API
      });
      notify("Clip updated successfully", { type: "success" });
    } catch (error) {
      notify("Error updating author", { type: "error" });
    }
  };

  useEffect(() => {
    // Fetch the clip when the component mounts
    if (record.clip_id) {
      loadNotes();
    }
  }, []);

  return (
    record.clip_id && (
      <>
        <TextInput
          source="clips_new.notes"
          label="Clip Notes"
          onBlur={() => update("clips_new.notes", "notes")}
          multiline
          fullWidth
        />
        <TextInput
          source="clips_new.ending"
          label="Clip Ending"
          onBlur={() => update("clips_new.ending", "ending")}
          multiline
          fullWidth
        />
      </>
    )
  );
};

export function EditDoseView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const {
    location: { hash = "" },
    id,
  } = window;

  const [onEditTab, setOnEditTab] = useState(false);

  useEffect(() => {
    console.log({ hash, id });
    const tabIndex = hash.split(`/daily_dose/${id}`).pop();
    if (tabIndex === "") {
      setOnEditTab(true);
    } else {
      setOnEditTab(false);
    }
  }, [id, hash]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) errors.title = "Title is required.";
    // if (!values.dose_number) errors.dose_number = "Dose number is required.";
    if (!values.lecture_id) errors.lecture_id = "Lecture Id is required.";
    // if (!values.released_at)
    //   errors.released_at = "Released at date is required.";

    return errors;
  };

  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Daily dose updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      <TabbedForm
        {...props}
        validate={(values) => validate(values)}
        toolbar={onEditTab ? undefined : <UploadThenSaveButton />}
      >
        <FormTab label="Edit" {...props} replace>
          <TextInput source="title" />
          <NumberInput source="dose_number" type="number" label="Dose Number" />
          <FunctionField
            label="Speaker"
            render={(record) => (
              <a
                href={`/speakers/${record.speaker_id}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${record.speaker_title_short} ${record.speaker_name_first} ${record.speaker_name_last}`}
              </a>
            )}
          />
          <FunctionField
            label="Corresponding Lecture"
            render={(record) => (
              <Link
                to={`https://www.torahanytime.com/lectures/${record.analog_lecture_id}`}
                target="_blank"
              >
                Corresponding Lecture
              </Link>
            )}
          />
          <FunctionField
            label="Admin for Corresponding Lecture"
            render={(record) => (
              <Link
                to={`/lectures/${record.analog_lecture_id}`}
                target="_blank"
              >
                Admin for Corresponding Lecture
              </Link>
            )}
          />
          <FunctionField
            label="Parent Lecture"
            render={(record) => (
              <Link
                to={`https://www.torahanytime.com/lectures/${record.lecture_id}`}
                target="_blank"
              >
                Parent Lecture
              </Link>
            )}
          />
          <NumberInput source="lecture_id" type="number" label="Lecture Id" />
          <BooleanInput source="display" label="Display" defaultValue={false} />
          <BooleanInput
            source="double_dose"
            label="Double Dose"
            defaultValue={false}
          />
          <BooleanInput
            source="is_classic"
            label="Classic Dose"
            defaultValue={false}
          />
          <DateInput source="released_at" />
          <FunctionField
            label="Source Clip (Admin)"
            render={(record) =>
              record.clip_id && (
                <Link to={`/clips_new/${record.clip_id}`} target="_blank">
                  Source Clip (Admin)
                </Link>
              )
            }
          />
          {/* Redirects to the refererenced record: <ReferenceField source="clip_id" reference="clips_new" label="Notes">
            <TextInput resource="clips_new" source="notes" label="Notes" />
          </ReferenceField> */}
          {/* Doesn't load anything: <ReferenceInput source="clip_id" reference="clips_new">
            <TextInput source="notes" label="Notes" />
          </ReferenceInput> */}
          {/* Loads record, but changes don't enable the save button:<ReferenceTextInput
            reference="clips_new"
            source="notes"
            label="Notes"
            recordIdSource="clip_id"
          /> */}
          {/* Doesn't work: <TextInput source="clips_new.notes" label="Notes" /> */}
          <ClipNotesInput />
          <ExistingUrlField source="audioUrl" />
          <ExistingUrlField source="videoUrl" />
          <ExistingUrlField source="videoUrlEmail" />
          <ExistingUrlField source="videoUrlWhatsapp" />
          <ExistingUrlField source="videoUrlHq" />
          <FunctionField
            label="Pipeline Progress"
            render={(record) => (
              <TemporalProgressField
                workflowId={`DailyDoseTranscoding-${record.id}`}
                activityNames={[
                  "DailyDoseAudio",
                  "DailyDoseEmailQuality",
                  "DailyDoseLowQuality",
                  "DailyDoseWhatsApp",
                  "DailyDoseHighQualityFast",
                  "DailyDoseHighQualitySlow",
                ]}
                total={record.duration}
                pollInterval={600}
              />
            )}
          />
          <FileInput
            source="FILE_original_video"
            accept="video/*"
            label="Original Video file"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
        <FormTab label="Photos">
          <ImageField
            source="speaker_dose_app_photo_url"
            label="Dose app photo"
            alt="Promotional graphic for the dose app"
          />
          <ImageField
            source="speaker_dose_email_photo_url"
            label="Dose email photo"
            alt="Promotional graphic for the dose email"
          />
          <ImageField
            source="speaker_double_dose_email_photo_url"
            label="Double Dose email photo"
            alt="Promotional graphic for the double dose email"
          />
          <FunctionField
            label="Instagram photo"
            render={(record) => (
              <img
                style={{ maxWidth: 200 }}
                src={`${baseURL}/images/proxy?filename=${record.dose_number}-instagram&url=${record.speaker_dose_instagram_photo_url}&text=Dose %23${record.dose_number?.toLocaleString("en")}&textX=617&textY=18&textFont=montserrat-regular-80-white&textMaxWidth=100`}
                alt="Promotional graphic for the dose on Instagram"
              />
            )}
          />
          <FunctionField
            label="Podcast photo"
            render={(record) => (
              <img
                style={{ maxWidth: 200 }}
                src={`${baseURL}/images/proxy?filename=${record.dose_number}-podcast&url=${record.speaker_dose_podcast_photo_url}&text=Dose %23${record.dose_number?.toLocaleString("en")}&textX=497&textY=10&textFont=in901xl-63-blue&textMaxWidth=100`}
                alt="Promotional graphic for the dose podcast"
              />
            )}
          />
        </FormTab>
        <FormTab label="WhatsApp" replace fullWidth>
          <FunctionField
            label="WhatsApp text"
            render={(record) => <WhatsAppTemplate record={record} id={id} />}
          />
        </FormTab>
        <FormTab label="Instagram" replace fullWidth>
          <FunctionField
            label="Instagram text"
            render={(record) => <InstagramTemplate record={record} id={id} />}
          />
        </FormTab>
        <FormTab label="Schedules">
          <FunctionField
            label="Schedules"
            render={(record) => <SchedulesTab record={record} />}
          />
        </FormTab>
        <FormTab label="Email">
          <FunctionField
            label="Email"
            render={(record) => <EmailTab record={record} />}
          />
        </FormTab>
        <FormTab label="Textual Renditions">
          <TextInput
            label="Transcription"
            multiline
            fullWidth
            source="transcription"
          />
          <TextInput label="Summary" multiline fullWidth source="summary" />
          <TextInput
            label="Description"
            multiline
            fullWidth
            source="description"
          />
          <TextInput label="Article" multiline fullWidth source="article" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
