import Button from "@mui/material/Button";
import { ListVideo } from "lucide-react";
import React, { useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  List,
  NumberField,
  ReferenceField,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  useRedirect,
} from "react-admin";
import {
  CardListActions,
  CategoryInput,
  EditorToolbar,
  isTabLocation,
  LanguageInput,
  SpeakerInput,
  SubCategoryInput,
  validation,
} from "../../components";
import { authClient } from "../../server";

const validate = {
  title: [validation.required(), validation.minLength(2)],
  speaker: [validation.required()],
  language_id: [validation.required()],
};

const SeriesLecturesView = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const [lecturesText, setLecturesText] = useState(
    "" + record.seriesLectures.map((row) => row.lecture_id).join("\n"),
  );
  const [updating, setUpdating] = useState(false); //not sure what this is for if it's always false, but if someone feels that they need to make this function more atomic, here it is...

  /** @param {React.KeyboardEvent<HTMLTextAreaElement>} e */
  const keyUp = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode === 13) {
      save(e);
    }
  };

  /** @param {React.SyntheticEvent<HTMLTextAreaElement>} e */
  const update = (e) => {
    setLecturesText(e.currentTarget.value);
  };

  const save = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (updating) {
      return;
    }
    const parts = lecturesText.split("\n").map((line) => line.trim());
    if (parts.length > 0) {
      if (
        !parts.reduce(
          (ok, line) => (!ok ? ok : !!line.match(/^[\d,]*[, \r]*$/g)),
          true,
        )
      ) {
        window.alert("Only numbers, commas and new lines are allowed.");
        return;
      }
    }

    authClient
      .post(`/admin/series/${record.id}/lectures`, {
        lectures_text: lecturesText,
      })
      .then((res) => {
        const {
          json: { lectures_text },
        } = res;
        console.log(res);
        setLecturesText(lectures_text);
        notify("Lectures updated.");
      })
      .catch((error) => notify(error.body.message, { type: "error" }));
  };

  return (
    <div style={styles.formArea}>
      <p>
        Please enter one or more Lecture ID, each separated by a new line.
        <br />
        The order of Lecture IDs here determines the default sort order.
      </p>
      <textarea
        value={lecturesText}
        onKeyUp={keyUp}
        onChange={update}
        rows="15"
        cols="40"
      ></textarea>
      <div>
        <Button variant="contained" color="primary" onClick={save}>
          Save Lectures
        </Button>
      </div>
    </div>
  );
};

const filters = [
  <TextInput source="id" alwaysOn />,
  <TextInput label="Title" source="title" alwaysOn />,
  <BooleanInput source="completed" alwaysOn />,
];

function validateForm(values) {
  const errors = {};
  if (!values.parent_category_id && !values.category_id) {
    errors.category_id = "Topic required.";
  }
  return errors;
}

function renderEditForm({ creating }) {
  return (
    <TabbedForm
      toolbar={isTabLocation(1) ? null : <EditorToolbar />}
      submitOnEnter={false}
      validate={validateForm}
    >
      <FormTab label="Details" replace>
        <ImageField source="image" label="Image" />
        <TextInput source="title" label="Title" validate={validate.title} />
        <SpeakerInput source="speaker_id" validate={validate.speaker} />
        <CategoryInput
          source="parent_category_id"
          label="Topic"
          allowEmpty={true}
        />
        <SubCategoryInput
          source="category_id"
          parentSource="parent_category_id"
          label=""
        />
        <LanguageInput source="language_id" validate={validate.language_id} />
        <BooleanInput source="display_active" label="Active" />
        <BooleanInput source="completed" label="Completed" />
        <BooleanInput source="female_only" label="Female only" />
        <ImageInput
          source="FILE_image"
          label="Image Upload"
          accept="image/*"
          placeholder={<p>Click or drop your file here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      {!creating && (
        <FormTab label="Lectures" replace>
          <SeriesLecturesView />
        </FormTab>
      )}
    </TabbedForm>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Series created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "series");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm({ creating: true })}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Series updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm({ creating: false })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      filters={filters}
      actions={<CardListActions />}
      perPage={25}
      sort={{ field: "id", order: "DESC" }}
      title="Series"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <ImageField source="image" label="Image" />
        <TextField source="title" label="Title" />
        <ReferenceField
          label="Speaker"
          source="speaker_id"
          reference="speakers"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Topic"
          source="category_id"
          reference="categories"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Language"
          source="language_id"
          reference="languages"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="lecture_count" label="Lectures" />
        <BooleanField source="display_active" label="Active" />
        <BooleanField source="completed" label="Completed" />
        <BooleanField source="female_only" label="Female only" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

const styles = {
  formArea: {
    lineHeight: "42px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: ".875rem",
  },
};

export const Series = {
  category: "tanext",
  name: "series",
  create: CreateView,
  edit: EditView,
  icon: ListVideo,
  roles: ["series_admin"],
  permissions: {
    series_admin: ["create", "list", "view", "edit"],
  },
  list: ListView,
  roles: ["series_admin"],
  permissions: {
    series_admin: ["create", "list", "view", "edit"],
  },
  options: { label: "Series" },
};
