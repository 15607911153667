import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  SimpleForm,
  TextField,
  NumberInput,
  TextInput,
  DateInput,
  DateField,
  FileInput,
  FileField,
  required,
  BooleanInput,
  BooleanField,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { EditorToolbar } from "../../components";
import { RichTextInput } from "ra-input-rich-text";
import { ScrollText } from "lucide-react";

function ListView(props) {
  return (
    <List {...props} sort="id">
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <NumberField source="id" />

        <TextField source="parsha" />

        <DateField source="newsletter_date" />
        <BooleanField source="display_active" label="visible" />
      </Datagrid>
    </List>
  );
}
function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <TextInput source="parsha" validate={required()} />
      <BooleanInput source="display_active" label="visible" />
      <div style={{ margin: "32px 0" }}>
        <TextInput source="pdf_url" />
        <FileInput
          source="FILE_data"
          label="PDF Upload"
          accept="application/pdf"
          placeholder={<p>Click or drop your file here.</p>}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </div>
      <div style={{ margin: "32px 0" }}>
        <DateInput source="newsletter_date" />
        <NumberInput source="hebrew_year" />
      </div>
      <TextInput source="image" label="Header Image" />
      <FileInput
        source="FILE_image_data"
        label="Header Image"
        accept="image/*"
        placeholder={<p>Click or drop your file here.</p>}
      >
        <FileField source="src-image" title="title" />
      </FileInput>

      <RichTextInput source="content" label="Content" />
    </SimpleForm>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Newsletter created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "newsletters");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Newsletter updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Edit>
  );
}

export const Newsletters = {
  category: "manage",
  roles: ["newsletter_admin", "admin"],
  name: "newsletters",
  create: CreateView,
  edit: EditView,
  icon: ScrollText,
  list: ListView,
  options: { label: "Weekly Newsletters" },
};
