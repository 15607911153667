import { Megaphone } from "lucide-react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  ImageField,
  ImageInput,
  List,
  NumberField,
  NumberInput,
  regex,
  required,
  SimpleForm,
  TextField,
  TextInput,
  UrlField,
  useRefresh,
  useNotify,
  useRedirect,
} from "react-admin";
import { EditorToolbar } from "../../components";

const validateImageLink = [
  // required(),
  regex(
    /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.%]+$/,
    "Must be a valid URL (http://...)",
  ),
];

const validateLink = regex(
  /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.%]+$/,
  "Must be a valid URL (http://...)",
);

const validateNumber = (values) => {
  const errors = {};
  if (values.cells > 3) {
    errors.cells = ["Please choose between 1-3 cells"];
  }
  return errors;
};
function ListView(props) {
  return (
    <>
      <h2>Active ads must total 2 or 3 cells</h2>
      <List {...props} sort={{ field: "updated_at", order: "DESC" }}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <NumberField source="id" />
          <TextField source="name" />
          <ImageField
            source="full_image"
            classes={{ image: "small-image" }}
            sortable={false}
          />
          <UrlField
            source="link"
            target="_blank"
            rel="noopener noreferrer"
            sortable={false}
          />
          <NumberField source="cells" validate={validateNumber} />
          <BooleanField source="show" sortable={false} />
          <DateField source="updated_at" showTime label="Updated at (GMT)" />
        </Datagrid>
      </List>
    </>
  );
}

// const PreviewImage = props => (
//   <a
//     href={props.record.link}
//     target="_blank"
//     title={props.record.name}
//     rel="noopener noreferrer"
//   >
//     <WideCard photo={props.record.image} containImage />
//   </a>
// );

function renderEditForm() {
  return (
    <SimpleForm validate={validateNumber} toolbar={<EditorToolbar />}>
      <BooleanInput source="show" />

      <TextInput source="name" required />
      <TextInput source="full_image" validate={validateImageLink} fullWidth />
      <ImageInput
        source="FILE_photo"
        label="Image Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <p style={{ width: "50%" }}>
        ads can be 1, 2, or 3 cells wide (with all active ads 2 or 3 cells),{" "}
        <br />
        Each cell has an aspect ratio of 5:1 <br />
        (i.e. an image spanning 2 cells would be 800x80, giving it a 10:1 ratio)
      </p>
      <NumberInput validate={required()} source="cells" />
      <TextInput source="link" validate={validateLink} fullWidth />

      {/* <PreviewImage /> */}
    </SimpleForm>
  );
}

// const ConditionalBooleanInput = ({ record, ...rest }) => {
//   return <BooleanInput record={record} {...rest} options={{ disabled: record.can_close }} />
// };

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Announcement created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "announcements");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Announcement updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Edit>
  );
}

export const Ads = {
  category: "tanext",
  name: "announcements",
  create: CreateView,
  edit: EditView,
  icon: Megaphone,
  list: ListView,
  options: { label: "Ads" },
};
