import { MailCheck } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import {
  CardListActions,
  DefaultBulkActionButtons,
  EditorToolbar,
} from "../../components";

function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar showDelete />}>
      <TextInput source="id" label="Email Address" />
    </SimpleForm>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Email subscribers created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "email_subscribers");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Email subscribers updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={[<TextInput source="id" alwaysOn label="Email Address" />]}
      perPage={50}
      sort={{ field: "id", order: "ASC" }}
      title="Subscribers"
      bulkActionButtons={<DefaultBulkActionButtons />}
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<DefaultBulkActionButtons />}
      >
        <TextField source="id" label="Email Address" />
      </Datagrid>
    </List>
  );
}

export const EmailSubscribers = {
  category: "email",
  name: "email_subscribers",
  create: CreateView,
  edit: EditView,
  icon: MailCheck,
  list: ListView,
  options: { label: "Subscribers" },
};
