import { Megaphone } from "lucide-react";
import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  NumberInput,
  TextInput,
  NumberField,
  required,
  BooleanInput,
  BooleanField,
  ReferenceInput,
  AutocompleteInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { ColorInput } from "react-admin-color-picker";
import { CardListActions } from "../../components";
import { filterByName } from "../../components/utils";

const PageDescription = () => (
  <div className="page-description">
    <h1>Lecture Messages</h1>
    <p>
      This page displays a list of all messages that appear in a message bar
      above videos on the platform. They can be associated with specific
      speakers, or can be general.
    </p>
  </div>
);

const AddEditPageDescription = () => (
  <div className="page-description">
    <h1>Add or Edit Preroll Ads</h1>
    <p>
      Use this page to create a new lecture bar message or update an existing
      one.
    </p>
  </div>
);

const filters = [
  <NumberInput source="id" label="Id" key="id" alwaysOn />,
  // <TextInput source="title" label="Title" key="title" alwaysOn />,
];

function ListView(props) {
  return (
    <>
      <PageDescription />
      <List
        {...props}
        actions={<CardListActions exporter={true} />}
        filters={filters}
        perPage={50}
        sort={{ field: "created_at", order: "DESC" }}
        title="Lecture Message Bar Messages"
      >
        <Datagrid rowClick="edit">
          <TextField source="id" label="ID" />
          <BooleanField source="display_active" label="Active?" />
          <TextField source="text_content" label="Message Text" />
          <TextField source="button_content" label="Button Text" />
          <TextField source="url" label="URL" />
        </Datagrid>
      </List>
    </>
  );
}

const textContentLengthValidator = (textContent, record) =>
  record.show_button || record.show_photo
    ? textContent.length < 100
      ? undefined
      : "Text Content is max 100 characters when button or photo are shown"
    : textContent.length < 150
      ? undefined
      : "Text Content is max 150 characters";

const knownVariables = ["speaker", "determiner", "ambassadorID"];
const textContentVariablesValidator = (textContent) => {
  const regex = /\$(\w+)/g; // Regular expression to find tokens starting with '$' followed by one or more word characters.
  let match;

  while ((match = regex.exec(textContent)) !== null) {
    const variableName = match[1]; // Extract the variable name (without the '$').

    if (!knownVariables.includes(variableName)) {
      return `Unknown variable in Text Content: ${variableName}`; // If the variable name is not in the knownVariables array, return error.
    }
  }

  return undefined; // If all variables are valid, return true.
};

const buttonTextLengthValidator = (buttonText, record) =>
  record.show_button_icon
    ? (buttonText?.length || 0) < 8
      ? undefined
      : "Button text is max 8 characters when it has an icon"
    : (buttonText?.length || 0) < 9
      ? undefined
      : "Button text is max 9 characters";
const LectureMessageForm = (redirect) => {
  // const { data: existingLectureMessages, isLoading: isPrerollLoading } = useGetList(
  //   "preroll_ads",
  //   {
  //     pagination: { page: 1, perPage: 10000 }, // Correct structure
  //     sort: { field: "id", order: "ASC" },
  //   },
  // );
  // // Extract all assigned speaker IDs from preroll ads
  // const speakerIdsWithLectureMessages = new Set(
  //   existingLectureMessages?.flatMap((ad) => ad.speaker_ids || []) || [],
  // );
  return (
    <SimpleForm redirect={redirect}>
      <AddEditPageDescription />
      <TextInput
        source="text_content"
        label="Message Text"
        validate={[
          required(),
          textContentLengthValidator,
          textContentVariablesValidator,
        ]}
        fullWidth
      />
      <TextInput
        source="generic_text_content"
        label="Generic Message Text"
        validate={[
          // required(),
          textContentLengthValidator,
          // textContentVariablesValidator,
        ]}
        fullWidth
      />
      <BooleanInput
        source="display_active"
        label="Is Active?"
        validate={[required()]}
      />
      <BooleanInput source="show_button" label="Show Button?" />
      <TextInput
        source="button_text"
        label="Button Text"
        validate={[buttonTextLengthValidator]}
      />
      <BooleanInput source="show_button_icon" label="Show Button Icon?" />
      <BooleanInput source="is_phone_callable" label="Is Phone-Callable?" />
      <TextInput source="phone_number" label="Phone Number" />
      <TextInput source="link_text" label="Link Text" />
      <ColorInput source="highlight_color" label="Highlight Color" />
      <TextInput source="url" label="URL" validate={[required()]} fullWidth />
      <BooleanInput source="show_photo" label="Show Photo?" />
      <ReferenceInput source="speaker_id" reference="speakers" label="Speaker">
        <AutocompleteInput
          // source="speaker_id"
          // optionValue="id"
          optionText={(choice) => choice?.name || ""}
          filterToQuery={filterByName}
        />
      </ReferenceInput>
      {/* <ReferenceArrayInput
        reference="speakers"
        source="speaker_ids"
        label="Speakers"
      >
        <AutocompleteArrayInput
          source="speaker_ids"
          optionValue="id"
          optionText={(choice) =>
            `${speakerIdsWithLectureMessages.has(choice.id) ? "✅" : ""} ${choice?.name || ""}`
          }
          filterToQuery={filterByName}
          fullWidth
          emptyText="Search Speaker"
        />
      </ReferenceArrayInput> */}
    </SimpleForm>
  );
};

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Lecture Message updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {LectureMessageForm(false)}
    </Edit>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Lecture Message created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "lecture_messages");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {LectureMessageForm("list")}
    </Create>
  );
}

export const LectureMessages = {
  category: "database",
  name: "lecture_messages",
  icon: Megaphone,
  list: ListView,
  edit: EditView,
  create: CreateView,
  options: { label: "Lecture Messages" },
};
