import { Receipt } from "lucide-react";
import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { CardListActions } from "../../components";
import DateTimeField from "../../components/field/DateTimeField";

const parseEmail = (record) => {
  return record.txn_response && record.txn_response.donor_email
    ? record.txn_response.donor_email
    : record.meta && record.meta.donor_email
      ? record.meta.donor_email
      : "";
};

const filters = [
  <TextInput label="Txn ID" source="txn_id" alwaysOn />,
  <TextInput label="TransID" source="transaction_id" alwaysOn />,
  <TextInput label="Email" source="donor_email" alwaysOn />,
  <SelectInput
    label="Status"
    source="status"
    alwaysOn
    choices={[
      { id: "success", name: "Success" },
      { id: "failed", name: "Failed" },
    ]}
  />,
  <TextInput source="id" alwaysOn />,
  <SelectInput
    label="Verified"
    source="verified"
    alwaysOn
    choices={[
      { id: 1, name: "Verified" },
      { id: 0, name: "Not Verified" },
    ]}
  ></SelectInput>,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={filters}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Campaign Donations"
    >
      <Datagrid rowClick="show">
        <TextField source="txn_id" label="Txn ID" />
        <ReferenceField
          label="Campaign Package"
          source="campaign_pckg_id"
          reference="campaign_packages"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="campaign_pckg_id" label="Campaign Pckg ID" />
        <TextField source="amount" label="Amount" />
        <FunctionField
          label="Transaction ID"
          render={(r) => (r.txn_response ? r.txn_response.transaction_id : "")}
        />
        <FunctionField label="Email" render={parseEmail} />
        <TextField source="status" label="Status" />
        <DateTimeField source="created_at" label="Created At" />
        <TextField source="id" label="Id" />
        <BooleanField source="verified" label="Verified" />
      </Datagrid>
    </List>
  );
}

function ShowView(props) {
  return (
    <Show
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={filters}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Campaign Packages"
    >
      <SimpleShowLayout>
        <TextField source="txn_id" label="Txn ID" />
        <TextField source="campaign_pckg_id" label="Campaign Pckg ID" />
        <TextField source="campaign_package.name" label="Campaign Pckg Name" />
        <TextField source="amount" label="Amount" />
        <FunctionField
          source="txn_response"
          label="Processor Response"
          render={(record) => JSON.stringify(record.txn_response)}
        />
        <FunctionField label="Email" render={parseEmail} />
        <FunctionField
          source="meta"
          label="Extra Info"
          render={(record) => JSON.stringify(record.meta)}
        />
        <TextField source="status" label="Status" />
        <TextField source="created_at" label="Created At" />
        <TextField source="id" label="Id" />
        <BooleanField source="verified" label="verified" />
      </SimpleShowLayout>
    </Show>
  );
}

export const CampaignDonations = {
  category: "database",
  name: "campaign_donations",
  icon: Receipt,
  list: ListView,
  show: ShowView,
  options: { label: "Campaign Donations" },
  roles: ["campaign_admin"],
  permissions: {
    campaign_admin: ["create", "list", "view"],
  },
};
