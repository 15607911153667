import { FormControlLabel, Switch } from "@mui/material";
import beautify from "js-beautify";
import { useState } from "react";
import AceEditor from "react-ace";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import {
  CardListActions,
  DefaultBulkActionButtons,
  EditorToolbar,
} from "../../components";

import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/keybinding-vscode";
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/theme-monokai";
import { MailPlus } from "lucide-react";

const AceHtmlInput = (props) => {
  const record = useRecordContext();
  const { onChange, html, setHtml, ...restInput } = props;
  const [split, setSplit] = useState(true);
  const onEditorChange = (newValue) => {
    setHtml(newValue);
    onChange?.(newValue);
  };
  return (
    <div style={{ width: "100%" }}>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={split}
              onChange={(e) => setSplit(e.target.checked)}
              name="checkedB"
              color="primary"
            />
          }
          label="Split View"
        />
      </div>
      <div style={{ display: "flex" }}>
        <AceEditor
          setOptions={{ useWorker: false, wrap: true }}
          width={split ? "49%" : "100%"}
          height="90vh"
          mode="html"
          theme="monokai"
          showPrintMargin={false}
          {...restInput}
          source="content"
          defaultValue={record.content}
          onLoad={(editor) => setHtml(editor.getValue())}
          onChange={onEditorChange}
          onBlur={() => onEditorChange(beautify.html(html))} // otherwise was erasing some content (maybe was sanitizing it)
        />
        <div
          style={{
            width: "49%",
            height: "100vh",
            display: split ? undefined : "none",
          }}
        >
          <iframe
            style={{ width: "100%", height: "100vh" }}
            srcDoc={html}
            title="AceHtmlInput"
          />
        </div>
      </div>
    </div>
  );
};

function renderEditForm({ html, setHtml }) {
  return (
    <SimpleForm toolbar={<EditorToolbar showDelete alwaysEnable />}>
      <TextInput source="name" label="Name" />
      <TextInput source="from" label="From" />
      <TextInput source="to" label="To" />
      <TextInput source="subject" label="Subject" />
      <AceHtmlInput
        source="content"
        label="Content"
        html={html}
        setHtml={setHtml}
      />
    </SimpleForm>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [html, setHtml] = useState();
  const onChangeHtml = (data) => ({
    ...data,
    content: html,
  });

  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Email Template created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "email-templates");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
      transform={onChangeHtml}
    >
      {renderEditForm({ html, setHtml })}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [html, setHtml] = useState();
  const onChangeHtml = (data) => ({
    ...data,
    content: html,
  });

  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Email Template updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
      transform={onChangeHtml}
    >
      {renderEditForm({ html, setHtml })}
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={[]}
      perPage={50}
      sort={{ field: "id", order: "ASC" }}
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<DefaultBulkActionButtons />}
      >
        <TextField source="id" label="Id" />
        <TextField source="name" label="Name" />
      </Datagrid>
    </List>
  );
}

export const EmailTemplates = {
  category: "dose",
  name: "email-templates",
  create: CreateView,
  edit: EditView,
  icon: MailPlus,
  list: ListView,
  options: { label: "Email Templates" },
};
