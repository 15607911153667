import { MonitorPlay } from "lucide-react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  ImageField,
  List,
  NumberField,
  NumberInput,
  ReferenceManyField,
  required,
  SimpleForm,
  TextField,
  TextInput,
  ImageInput,
  regex,
  SearchInput,
  NullableBooleanInput,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { EditorToolbar } from "../../components";
import TotalField from "../../components/field/TotalField";

function ListView(props) {
  return (
    <List
      filters={[
        <TextInput source="name" alwaysOn />,
        <BooleanInput
          format={(value) => value === 1}
          parse={(value) => (value === true ? 1 : 0)}
          label="Active"
          source="display_active"
          alwaysOn
        />,
      ]}
      {...props}
    >
      <a
        style={{ fontSize: 24, marginInline: 16, lineHeight: 3 }}
        target="_blank"
        href="https://app.tango.us/app/workflow/Creating-Tisha-Bav-Programs--Classes--and-Ads-with-Torah-Anytime-150392314da44cf0a5a3045b19d28575"
      >
        Click here to view a walkthrough for adding programs & classes
      </a>
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <ImageField source="img" sortable={false} />
        <TextField source="name" />
        <ReferenceManyField
          label="Number of Classes"
          reference="tisha_bav_classes"
          target="tisha_bav_program"
          source="id"
          className="program_table"
        >
          <TotalField />
        </ReferenceManyField>
        <TextField source="phone_extension" />
        <BooleanField source="display_active" />
      </Datagrid>
    </List>
  );
}
function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <BooleanInput source="display_active" defaultValue={0} />

      <TextInput source="name" validate={required()} />
      <TextInput
        source="slug"
        label="slug (name for URL)"
        validate={required()}
      />
      <TextInput source="phone_extension" />
      <TextInput source="iframe_url" validate={required()} />
      <br />
      <br />
      <h4>Image</h4>
      <p>Image aspect ratio 1:1 (square)</p>
      <TextInput source="img" label="Image URL" />
      <p>-or-</p>
      <ImageInput
        source="FILE_photo"
        label="Image Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Tisha bav programs created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "tisha_bav_programs");
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Tisha bav programs updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Edit>
  );
}

export const Programs = {
  category: "tbav",
  name: "tisha_bav_programs",
  create: CreateView,
  edit: EditView,
  icon: MonitorPlay,
  list: ListView,
  options: { label: "Tisha Bav Programs" },
};
