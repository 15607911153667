import { Megaphone } from "lucide-react";
import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  NumberInput,
  TextInput,
  ImageField,
  NumberField,
  required,
  ImageInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useGetList,
  BooleanInput,
  BooleanField,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { CardListActions } from "../../components";
import { filterByName } from "../../components/utils";

const PageDescription = () => (
  <div className="page-description">
    <h1>Preroll Ads</h1>
    <p>
      This page displays a list of all preroll ads that appear before videos on
      the platform. Each ad includes details like skip time, duration, and
      associated speakers.
    </p>
  </div>
);

const AddEditPageDescription = () => (
  <div className="page-description">
    <h1>Add or Edit Preroll Ads</h1>
    <p>
      Use this page to create a new preroll ad or update an existing one.
      Provide the required details, such as skip time, duration, image, and
      associated speakers.
    </p>
  </div>
);

const filters = [
  <NumberInput source="id" label="Id" key="id" alwaysOn />,
  <TextInput source="title" label="Title" key="title" alwaysOn />,
];

function ListView(props) {
  return (
    <>
      <PageDescription />
      <List
        {...props}
        actions={<CardListActions exporter={true} />}
        filters={filters}
        perPage={50}
        sort={{ field: "created_at", order: "DESC" }}
        title="Preroll Ads"
      >
        <Datagrid rowClick="edit">
          <TextField source="id" label="ID" />
          <BooleanField source="is_active" label="Active?" />
          <TextField source="title" label="Title" />
          <NumberField
            source="skip_after_seconds"
            label="Skip Time (seconds)"
          />
          <NumberField source="duration" label="Duration (Hours)" />
          <TextField source="url" label="URL" />
          <ImageField source="image_url" label="Image" />
          {/* <TextField source="speakers" label="Speakers" /> */}
        </Datagrid>
      </List>
    </>
  );
}
const SpeakerFullNameField = ({ record }) => {
  return record ? `${record.name_first} ${record.name_last}` : "";
};

const PrerollAdForm = (redirect) => {
  const { data: existingPrerollAds, isLoading: isPrerollLoading } = useGetList(
    "preroll_ads",
    {
      pagination: { page: 1, perPage: 10000 }, // Correct structure
      sort: { field: "id", order: "ASC" },
    },
  );
  // Extract all assigned speaker IDs from preroll ads
  const speakerIdsWithPrerollAds = new Set(
    existingPrerollAds?.flatMap((ad) => ad.speaker_ids || []) || [],
  );
  return (
    <SimpleForm redirect={redirect}>
      <AddEditPageDescription />
      <TextInput source="title" label="Title" validate={[required()]} />
      <BooleanInput
        source="is_active"
        label="Is Active"
        validate={[required()]}
      />
      <BooleanInput
        source="skip_lecture_ad"
        label="Skip Lecture Ad?"
        validate={[required()]}
      />
      <NumberInput
        source="skip_after_seconds"
        label="Skip Time (seconds)"
        validate={[required()]}
      />
      <NumberInput
        source="duration"
        label="Duration (seconds)"
        validate={[required()]}
      />
      <NumberInput
        source="hide_for"
        label="Hide For (minutes)"
        validate={[required()]}
      />
      <TextInput source="button_text" label="Button Text" />
      <TextInput source="url" label="URL" validate={[required()]} fullWidth />
      <TextInput source="description" label="Description" multiline fullWidth />
      <TextInput
        source="video_url"
        label="Video URL"
        validate={[required()]}
        fullWidth
      />
      <ImageInput
        source="FILE_photo"
        label="Photo Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceArrayInput
        reference="speakers"
        source="speaker_ids"
        label="Speakers"
      >
        <AutocompleteArrayInput
          source="speaker_ids"
          optionValue="id"
          optionText={(choice) =>
            `${speakerIdsWithPrerollAds.has(choice.id) ? "✅" : ""} ${choice?.name || ""}`
          }
          filterToQuery={filterByName}
          fullWidth
          emptyText="Search Speaker"
        />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Preroll ad updated successfully!", { type: "success" });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {/* <Edit {...props} undoable={false}> */}
      {PrerollAdForm(false)}
    </Edit>
  );
}

function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Preroll ad created successfully!", { type: "success" });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "preroll_ads"); // Redirect back to list after update
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {PrerollAdForm("list")}
    </Create>
  );
}

export const PrerollAds = {
  category: "database",
  name: "preroll_ads",
  icon: Megaphone,
  list: ListView,
  edit: EditView,
  create: CreateView,
  options: { label: "Preroll Ads" },
};
