import { UploadCloud } from "lucide-react";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";
import { AutoRefreshView } from "../../components";
import { TranscodingStatus } from "../../components/TranscodingStatus";
import { BulkActionsView, PublishingStatuses } from "./shared";

const filters = [
  <TextInput
    key="lecture_id"
    label="Lecture ID"
    source="lecture_id"
    alwaysOn
  />,
  <TextInput key="title" label="Title" source="lecture_title" alwaysOn />,
  <TextInput key="speaker" label="Speaker" source="speaker_name" alwaysOn />,
];

function ListView(props) {
  return (
    <List
      {...props}
      filters={filters}
      perPage={25}
      sort={{ field: "created", order: "DESC" }}
      title={
        <span>
          Rendering
          <small style={{ fontSize: 12, float: "right" }}>
            Auto refresh: 30 seconds
          </small>
          <AutoRefreshView seconds={30} />
        </span>
      }
    >
      <Datagrid rowClick="show" bulkActionButtons={<BulkActionsView />}>
        <TextField source="lecture_id" />
        <TranscodingStatus />
        <TextField source="lecture_title" />
        <TextField source="speaker_name" />
        <SelectField source="publishing_status" choices={PublishingStatuses} />
        <TextField source="name" />
        <TextField source="size" />
        <TextField source="user_id" />
        <TextField source="id" />
        <TextField source="mime_type" />
        <DateField source="updated_at" showTime showDate={false} />
      </Datagrid>
    </List>
  );
}

function ShowView(props) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Rendering">
          <TextField source="lecture_id" />
          <TextField source="lecture_title" />
          <TextField source="speaker_name" />
          <SelectField
            source="publishing_status"
            choices={PublishingStatuses}
          />
          <TextField source="name" />
          <TextField source="size" />
          <TextField source="user_id" />
          <TextField source="mime_type" />
          <DateField source="created" showTime />
          <NumberField source="upload_time" />
          <TextField source="duration" />
          <BooleanField source="has_audio" />
          <TextField source="media_type" />
          <TextField source="error" />
          <UrlField source="file_url" target="_blank" />
          <BooleanField source="is_clip" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const UploadsRendering = {
  category: "database",
  name: "uploads-rendering",
  icon: UploadCloud,
  list: ListView,
  options: { label: "Rendering" },
  show: ShowView,
};
