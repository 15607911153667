import { FileCode2 } from "lucide-react";
import {
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  UrlField,
  useRedirect,
  useRefresh,
  useNotify,
} from "react-admin";
import { CardListActions, EditorToolbar } from "../../components";

const filters = [
  <BooleanInput label="Active" source="display_active" alwaysOn />,
  <TextInput source="id" alwaysOn />,
  <TextInput source="title" alwaysOn />,
  <TextInput source="slug" alwaysOn />,
  <TextInput source="author_name" alwaysOn />,
  <TextInput source="author_email" alwaysOn />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filterDefaultValues={{ display_active: 1 }}
      filters={filters}
      perPage={25}
      sort={{ field: "created_at", order: "DESC" }}
      title="Posts"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <DateField source="created_at" showTime />
        <TextField source="title" />
        <TextField source="slug" />
        <TextField source="author_name" />
        <TextField source="author_email" />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Post updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      <SimpleForm
        onChange={(value) => {
          if (!value.slug) {
            value.slug =
              value.title && value.title.replace(/\s/g, "-").toLowerCase();
          }
        }}
        toolbar={<EditorToolbar />}
      >
        <DateField source="created_at" showTime />
        <TextField source="author_name" />
        <TextField source="author_email" />
        <UrlField
          source="url"
          label="Website"
          target="_blank"
          rel="noopener noreferrer"
        />
        <BooleanInput source="display_active" />
        <TextInput source="title" />
        <TextInput source="slug" label="Slug" />
        <BooleanInput source="comments_allow" />
        <BooleanInput source="comments_show" />
        <BooleanInput source="needs_password" />
        <TextInput source="password" />
      </SimpleForm>
    </Edit>
  );
}

export const Posts = {
  category: "database",
  name: "posts",
  icon: FileCode2,
  list: ListView,
  options: { label: "Posts" },
  edit: EditView,
};
