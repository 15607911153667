import { FlaskConical } from "lucide-react";
import {
  Datagrid,
  List,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { CardListActions } from "../../components";
import DateTimeField from "../../components/field/DateTimeField";
import SendEmailButton from "./SendEmailButton";

const filters = [
  <TextInput label="First Name" source="first_name" alwaysOn />,
  <TextInput label="Last Name" source="last_name" alwaysOn />,
  <TextInput label="Email" source="email" alwaysOn />,
  <TextInput source="sent_email" label="Email Sent" alwaysOn />,
  <TextInput source="completed_registration" label="Registered" alwaysOn />,
  <TextInput source="id" alwaysOn />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={filters}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Beta Users"
    >
      <Datagrid rowClick="show" bulkActionButtons={<SendEmailButton />}>
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <TextField label="Phone" source="phone" />
        <TextField source="email" label="Email" />
        <TextField source="verify_attempts" label="Verification Attempts" />
        <TextField source="sent_email" label="Email Sent" />
        <TextField source="completed_registration" label="Registered" />
        <DateTimeField source="created_at" label="Submitted At" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}

function ShowView(props) {
  return (
    <Show
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={filters}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Beta Users"
    >
      <SimpleShowLayout>
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <TextField label="Phone" source="phone" />
        <TextField source="email" label="Email" />
        <TextField source="verify_attempts" label="Verification Attempts" />
        <TextField source="sent_email" label="Email Sent" />
        <TextField source="completed_registration" label="Registered" />
        <DateTimeField source="created_at" label="Submitted At" />
        <TextField source="id" label="Id" />
      </SimpleShowLayout>
    </Show>
  );
}

export const BetaUsers = {
  category: "database",
  name: "beta_users",
  icon: FlaskConical,
  list: ListView,
  show: ShowView,
  options: { label: "Beta Users" },
};
