import React from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  FormDataConsumer,
  List,
  NumberField,
  SelectInput,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from "react-admin";
// import { reduxForm } from "redux-form";
import resourceIcon from "@mui/icons-material/AttachMoney";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  CardListActions,
  createResourceURL,
  formatDateTimeInput,
  JsonTextField,
  parseDateTimeIntUTC,
  PreformattedTextField,
} from "../../components";

const sources = [
  { id: "stripe", name: "stripe" },
  { id: "paypal", name: "paypal" },
];

const filters = [
  <SelectInput source="source" choices={sources} alwaysOn />,
  <TextInput source="email" alwaysOn />,
  <TextInput source="user_name" alwaysOn />,
  <DateInput
    label="From"
    source="time.>"
    alwaysOn
    format={formatDateTimeInput}
    parse={parseDateTimeIntUTC}
  />,
  <DateInput
    label="To"
    source="time.<"
    alwaysOn
    format={formatDateTimeInput}
    parse={parseDateTimeIntUTC}
  />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={filters}
      perPage={10}
      sort={{ field: "time", order: "DESC" }}
      title="Donations"
    >
      <Datagrid rowClick="show">
        <TextField source="source" />
        <NumberField
          source="amount_num"
          label="Amount"
          options={{ style: "currency", currency: "USD" }}
          sortable={false}
        />
        <TextField source="email" sortable={false} />
        <TextField source="user_name" sortable={false} />
        <NumberField source="other_donations_count" sortable={false} />
        <DateField source="time" showTime />
        <TextField source="id" sortable={false} />
      </Datagrid>
    </List>
  );
}

const styles = {
  clickable: {
    cursor: "pointer",
  },
};

function clickedOtherDonations(push, id) {
  return () => {
    const url = createResourceURL("donations", id, "show");
    push(url);
  };
}

function renderOtherDonations(props) {
  const {
    formData = { other_donations: [] },
    push,
    // ...rest
  } = props;
  const { other_donations = [] } = formData;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell variant="head">Source</TableCell>
          <TableCell variant="head">Amount</TableCell>
          <TableCell variant="head">Email</TableCell>
          <TableCell variant="head">User name</TableCell>
          <TableCell variant="head">Time</TableCell>
          <TableCell variant="head">Id</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {other_donations.map((data) => (
          <TableRow
            key={data.id}
            onClick={clickedOtherDonations(push, data.id)}
            style={styles.clickable}
          >
            <TableCell>
              <TextField record={formData} source="source" />
            </TableCell>
            <TableCell>
              <NumberField
                record={data}
                source="amount_num"
                label="Amount"
                options={{ style: "currency", currency: "USD" }}
                sortable={false}
              />
            </TableCell>
            <TableCell>
              <TextField record={formData} source="email" sortable={false} />
            </TableCell>
            <TableCell>
              <TextField
                record={formData}
                source="user_name"
                sortable={false}
              />
            </TableCell>
            <TableCell>
              <DateField record={data} source="time" showTime />
            </TableCell>
            <TableCell>
              <TextField record={data} source="id" sortable={false} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

class OtherDonationsView extends React.PureComponent {
  render() {
    return (
      <FormDataConsumer {...this.props}>
        {renderOtherDonations}
      </FormDataConsumer>
    );
  }
}
const OtherDonations = OtherDonationsView;

function ShowView(props) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Donation" replace>
          <TextField source="source" />
          <TextField source="amount" />
          <TextField source="campaign" />
          <TextField source="name" />
          <TextField source="email" />
          <TextField source="user_name" />
          <TextField source="gender" />
          <NumberField source="other_donations_count" label="Other Donations" />
          <PreformattedTextField source="purpose" />
          <DateField source="time" showTime />
          <TextField source="id" />
        </Tab>
        <Tab label="All Details" replace>
          <JsonTextField source="data" />
        </Tab>
        <Tab label="Other Donations" replace>
          <OtherDonations />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const DonationsLegacy = {
  category: "database",
  name: "donations_legacy",
  icon: resourceIcon,
  list: ListView,
  options: { label: "Donations (Legacy)" },
  show: ShowView,
  hidden: true,
};
