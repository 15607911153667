import React, { useState, useEffect } from "react";
import { useRecordContext } from "react-admin";
import { authClient } from "../server";

export const TranscodingStatus = () => {
  const record = useRecordContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isTranscoding, setIsTranscoding] = useState(false);
  const [progress, setProgress] = useState("-");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!record || !record.lecture_id) return;

    const fetchStatus = async () => {
      try {
        const response = await authClient.get(
          `/temporal/workflows/x/LectureTranscoding-${record.lecture_id}`,
        );
        if (response?.json) {
          /** Heartbeat payload is Base64-encoded JSON */
          const ffmpegActivitiy = response.json.raw.pendingActivities?.find(
            (pendingActivity) => pendingActivity.activityType.name === "ffmpeg",
          );
          if (!ffmpegActivitiy) {
            setIsTranscoding(false);
          } else {
            setIsTranscoding(true);
            const base64HeartbeatPayload =
              ffmpegActivitiy?.heartbeatDetails?.payloads[0].data ||
              btoa("null");
            console.log("base64HeartbeatPayload", base64HeartbeatPayload);
            const heartbeatData = JSON.parse(atob(base64HeartbeatPayload));
            setProgress(
              `${((heartbeatData?.progressRatio || 0) * 100).toFixed(2)}%`,
            );
          }
        } else {
          setProgress("No data available");
        }
      } catch (err) {
        console.error("Error fetching transcoding status:", err);
        setError(`Error: ${err.message || "Failed to fetch status"}`);
        setProgress("Error fetching status");
      }
      setIsLoading(false);
    };

    setIsLoading(true);
    fetchStatus();
  }, [record]);

  if (isLoading) {
    return <>Loading...</>;
  }
  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }
  if (!isTranscoding) {
    return <>-</>;
  }

  return <>{progress}</>;
};
