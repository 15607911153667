import { Gift } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { CardListActions, EditorToolbar } from "../../components";

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={[
        <TextInput label="Name" source="name" alwaysOn />,
        <TextInput source="id" alwaysOn />,
      ]}
      perPage={50}
      sort={{ field: "created_at", order: "DESC" }}
      title="Campaign Packages"
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
        <TextField source="sort_score" label="Sort Score" />
        <TextField source="category" label="Category" />
        <TextField source="display_category" label="Display Category" />
        <TextField source="price" label="Amount" />
        <TextField source="monthly" label="Monthly Amount" />
        <TextField source="id" label="Id" />
      </Datagrid>
    </List>
  );
}
function renderEditForm() {
  return (
    <SimpleForm toolbar={<EditorToolbar />}>
      <NumberField source="id" label="Id" />
      <TextInput source="name" label="Name" />
      <TextInput source="description" label="Description" />
      <SelectInput
        label="Category"
        source="category"
        choices={[
          { id: "shiur", name: "Shiur" },
          { id: "website", name: "Website" },
          { id: "daily_dose", name: "Daily Dose" },
          { id: "corporate_sponsor", name: "Corporate Ssponsor" },
          { id: "executive_sponsor", name: "Executive Sponsor" },
          { id: "general", name: "General Donation" },
        ]}
      />
      <SelectInput
        label="Display Category"
        source="display_category"
        choices={[
          { id: "website", name: "Website" },
          { id: "executive_sponsor", name: "Executive Sponsor" },
          { id: "general", name: "General Donations" },
          { id: "none", name: "Dont Display" },
        ]}
      />
      <NumberInput source="sort_score" label="Sort Score" />
      <NumberInput source="price" label="Amount" />
      <NumberInput source="monthly" label="Monthly Amount" />
    </SimpleForm>
  );
}

function EditView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Campaign packages updated successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect(false);
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {/* <Edit {...props} redirect={false} undoable={false}> */}
      {renderEditForm()}
    </Edit>
  );
}
function CreateView(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify("Campaign packages created successfully!", {
            type: "success",
          });
          refresh(); // Ensures UI updates with the new data
          redirect("list", "campaign_packages"); // Redirect back to list after update
        },
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }}
    >
      {renderEditForm()}
    </Create>
  );
}
export const CampaignPackages = {
  category: "database",
  name: "campaign_packages",
  icon: Gift,
  list: ListView,
  edit: EditView,
  create: CreateView,
  options: { label: "Campaign Packages" },
};
